@use "../../scss/variables.scss" as var;

#Login {
  .left-panel {
    background: var.$light-beige-grey !important;

    .floorplan-illustration {
      height: 100%;
      width: 100%;
      position: absolute;
      opacity: 1;
      background-image: url("../../assets/backgrounds/floorplan.png");
      background-size: 150%;
      background-position: 40% -100px;
      background-repeat: repeat;
    }

    .text-wrapper {
      z-index: 1;
    }

    .visit-website-button {
      background: var.$light-beige-grey;
    }
  }

  .right-panel {
    background: var.$dark-blue;
  }
}
