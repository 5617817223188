@use "../../../scss/variables.scss" as var;

#AddAlarmModal {
  width: 60vw;

  .pagetitle-title {
    font-size: 20px;
  }

  hr {
    margin-bottom: 25px;
  }

  input {
    @include var.InputStyling;
  }

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .preview {
    padding-bottom: 10px;

    .placeholder {
      border-radius: 12px;
      border: 2px solid #eaeaea;
      max-width: 100%;
      padding: 20px;
    }
  }

  .button-bottom-panel {
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 20px;

      .view-button {
        @include var.PrimaryButton;
      }
    }
  }
}
