@use "sass:color";

$primary-color: #072f41;
$secondary-color: #e5e1da;

$dark-blue: #072f41;
$warm-beige-grey: #d3c9ba;
$light-beige-grey: #e6e1da;
$lighter-beige-grey: #ebe8e3;
$dark-red-accent: #914141;
$light-blue-accent: #aac8ca;
$yellow-accent: #f4bf56;
$green-accent: #4c6c52;
$off-white: #fdfdfb;
$left-nav-blue: #0a2f41;

$blue: #0866c6;
$green: #43a047;
$yellow: #f49917;

$body-bg: $light-beige-grey;
$body-color: #868ba1;

// Border
$border-radius: 3px;

// Color
$orange: #f27510;
$teal: #1caf9a;

$blue: #0866c6; // primary
$green: #43a047; // success
$yellow: #f49917; // warning
$red: #dc3545; // danger
$cyan: #17a2b8; // info

// Forms elements
$input-btn-padding-y: 0.65rem;
$input-focus-box-shadow: none;

// Custom Forms
$ckbox-size: 16px;
$rdiobox-size: 16px;

// Form states and alerts
$state-success-bg: color.scale($green, $lightness: 40%);
$state-success-border: color.scale($green, $lightness: 35%);

$state-info-bg: color.scale($blue, $lightness: 40%);
$state-info-border: color.scale($blue, $lightness: 35%);

$state-warning-bg: color.scale($yellow, $lightness: 30%);
$state-warning-border: color.scale($yellow, $lightness: 25%);

$state-danger-bg: color.scale($red, $lightness: 25%);
$state-danger-border: color.scale($red, $lightness: 20%);

// Fonts
$font-family-hind: "Hind", "Helvetica Neue", Arial, sans-serif;
$font-family-firasans: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-roboto: "Roboto", "Helvetica Neue", Arial, sans-serif;
$font-family-poppins: "Poppins", "Helvetica Neue", Arial, sans-serif;
$font-family-source: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
$font-family-montserrat: "Montserrat", "Fira Sans", "Helvetica Neue", Arial,
  sans-serif;
$font-family-lato: "Lato", "Helvetica Neue", Arial, sans-serif;
$font-family-barcode: "Libre Barcode 39 Extended Text", cursive;
$font-family-archivo: "Archivo Regular", sans-serif;
$font-family-archivo-light: "Archivo Light", sans-serif;
$font-family-archivo-medium: "Archivo Medium", sans-serif;
$font-family-archivo-semibold: "Archivo Semibold", sans-serif;
$font-family-archivo-bold: "Archivo Bold", sans-serif;
$font-family-ballinger-bold: "Ballinger Bold", sans-serif;

$font-family-base: $font-family-archivo;
$font-size-base: 0.875rem;

// Links
$link-hover-decoration: none;

// Table
$table-border-color: #dee2e6;

// CUSTOM VARIABLES NOT BELONG TO BOOTSTRAP //
$br-header-height: 80px;
$br-header-height-mobile: 40px;
$br-header-bg: #fff;
$br-sideleft-width: 230px;
$br-subleft-width: 230px;
$br-sideright-width: 280px;

$br-primary: $primary-color;
$br-secondary: $secondary-color;

@mixin GreyAndBlackButton {
  border: 1px #d9d9d9 solid;
  height: 32px;
  padding: 0 10px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  color: #515151;
  cursor: pointer;
  min-width: 76px;
  font-family: $font-family-archivo-medium !important;

  &.active {
    background: black;
    color: white;
    height: 32px;
    border: 1px solid black;
  }

  &.disabled {
    opacity: 0.3;
  }
}

@mixin CardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

@mixin ButtonBase {
  border-radius: 50px;
  font-weight: bolder;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
}

@mixin PrimaryButton {
  @include ButtonBase;
  color: white;
  border: black 1px solid;
  background-color: black;
  transition: box-shadow 0.1s linear;
  box-shadow: 0px 0px 0px rgb(0, 0, 0);

  &:not(.disabled):hover {
    box-shadow: 3px 4px 5px rgb(41, 41, 41);
  }
}

@mixin SecondaryButton {
  @include ButtonBase;
  color: black;
  border: black 1px solid;
  background-color: white;

  &:not(.disabled):hover {
    background-color: rgb(227, 227, 227);
  }
}

@mixin InputStyling {
  color: #313131;
  font-weight: lighter;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid #eaeaea;
  background-color: #eaeaea;
  padding: 7.5px 11px;
  width: 100%;
}

@mixin InputStylingWhite {
  color: #343a40;
  font-weight: lighter;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid $off-white;
  background-color: $off-white;
  padding: 7.5px 11px;
  width: 100%;
}
