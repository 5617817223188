@use "../../scss/variables.scss" as var;

$menu-width: 250px;
$background-color: #194a5f;
$font-color: var.$off-white;
$black-font-color: #343a40;
$separator-color: linear-gradient(to right, #1caf9a 0%, #17a2b8 100%);
$separator-color-vertical: linear-gradient(to bottom, #1caf9a 0%, #17a2b8 100%);
$gray-background: #e9ecef;
$comparison-highlight: #e9f5f7;
$box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);

$small-font: 13px;

$element-margin: 10px;
$section-margin: 20px;

.dataExplorer {

  &.outOfLogin {
    left: 0 !important;
  }

  .contextualMenu {
    position: fixed;
    top: var.$br-header-height;
    left: var.$br-sideleft-width;
    bottom: 0px;
    width: $menu-width;
    background: $background-color;
    padding: 20px 0;
    border-top-left-radius: 0px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    transition: left 0.2s ease-in-out;
    z-index: 2;

    &.closed {
      left: -20px;
    }

    .label {
      color: $font-color;
      font-size: 18px;
      font-family: var.$font-family-archivo-semibold;
      padding: 0 20px;
    }

    .configButtons {
      height: 40px;
      display: flex;
      margin-top: 20px;
      width: 100%;
      padding: 0 20px;

      flex-direction: row;
      gap: 15px;

      button {
        flex: 1;
      }
    }

    .configList {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      padding: 0 10px;

      flex-grow: 1;
      overflow: scroll;
      gap: 5px;

      .configItem {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 10px;
        border-radius: 12px;
        cursor: pointer;

        &.highlighted {
          background: linear-gradient(to right, #17a2b8 0%, #1caf9a 100%);
        }

        &:not(.highlighted):hover {
          background: linear-gradient(to right, #0d5d69 0%, #106659 100%);
        }

        .configName {
          font-size: 14px;
          color: $font-color;
          cursor: pointer;
        }

        .configDelete {
          cursor: pointer;

          button {
            padding: 15px;
            background: #b53737;
            border: none;
            outline: none;
            border-radius: 50%;
            transition: none;

            &:hover {
              background: #d04b4b;
            }
          }
        }
      }
    }

    .menuButton {
      background: #194a5f;
      border: none;
      margin-left: auto;
      position: absolute;
      top: 20px;
      border-radius: 0 5px 5px 0;
      right: -20px;
      height: 30px;
      margin: 0;

      &:hover {
        background: #194a5f;
      }
    }
  }

  .chartsArea {
    margin-left: $menu-width;
    min-height: calc(100vh - 80px);
    overflow: scroll;
    transition: margin-left 0.2s ease-in-out;

    &.menuClosed {
      margin-left: 0;
    }

    .chartsContainer {
      margin: 20px 40px !important;
      display: block;

      @media (max-width: 1200px) {
        margin: 15px 20px !important;
      }

      .configHeader {
        min-height: 36px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        flex-wrap: wrap;
        gap: 10px;

        .configLeft {
          display: flex;
        }

        .configRight {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          flex: 1;
          gap: 10px;

          .addChart button {
            padding: 5px 15px;
            height: 100%;
            font-size: 16px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            background: #1c7430;
            color: white;
            border: none;
            outline: none;

            &:hover {
              background: #2e9850 !important;
              color: white !important;
            }
            &:disabled {
              opacity: 0.5;
            }
          }

          .shareButton {
            button {
              padding: 5px 15px;
              height: 100%;
              font-size: 16px;
              border-radius: 12px;
              display: flex;
              align-items: center;
              background: #415fa9;
              color: white;
              border: none;
              outline: none;
              transition: background 0.2s ease-in-out;

              &:hover {
                color: white !important;
              }
              &:disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .chartsArea {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 991px) {
    .contextualMenu {
      left: 0px !important;
      border-top-left-radius: 0;

      &.closed {
        left: -$menu-width !important;
        .collapse-button {
          right: -15px;
        }
      }
    }
  }
}
