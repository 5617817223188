.modal-wrapper {
  .modal-title {
    margin-bottom: 20px;
  }
  .modal-body {
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      width: 150px;
    }
  }
}
