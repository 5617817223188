/* ---------------------------------------------- */
/* ############### 7.9 Typography ############### */
/* ---------------------------------------------- */

// Font weights
.tx-black {
  font-weight: 900 !important;
}
.tx-bold {
  font-weight: 700 !important;
}
.tx-semibold {
  font-weight: 600 !important;
}
.tx-medium {
  font-weight: 500 !important;
}
.tx-normal {
  font-weight: 400 !important;
}
.tx-light {
  font-weight: 300 !important;
}
.tx-thin {
  font-weight: 200 !important;
}
.tx-xthin {
  font-weight: 100 !important;
}

// Font Family
.tx-poppins {
  font-family: $font-family-poppins !important;
}
.tx-roboto {
  font-family: $font-family-roboto !important;
}
.tx-hind {
  font-family: $font-family-hind !important;
}
.tx-firasans {
  font-family: $font-family-firasans !important;
}
.tx-source {
  font-family: $font-family-source !important;
}
.tx-mont {
  font-family: $font-family-montserrat !important;
}
.tx-lato {
  font-family: $font-family-lato !important;
}
.tx-sserif {
  font-family: $font-family-sans-serif !important;
}
.tx-barcode {
  font-family: $font-family-barcode !important;
}

.tx-archivo {
  font-family: $font-family-archivo !important;
}
.tx-archivo-light {
  font-family: $font-family-archivo-light !important;
}
.tx-archivo-medium {
  font-family: $font-family-archivo-medium !important;
}
.tx-archivo-bold {
  font-family: $font-family-archivo-bold !important;
}
.tx-archivo-semibold {
  font-family: $font-family-archivo-semibold !important;
}
.tx-ballinger-bold {
  font-family: $font-family-ballinger-bold !important;
}

// Font colors
.tx-white {
  color: #fff !important;
}
.tx-black {
  color: $black !important;
}
.tx-primary {
  color: $blue !important;
}
.tx-success {
  color: $green !important;
}
.tx-warning {
  color: $yellow !important;
}
.tx-danger {
  color: $red !important;
}
.tx-info {
  color: $cyan !important;
}
.tx-inverse {
  color: $gray-800 !important;
}
.tx-teal {
  color: $teal !important;
}
.tx-dark {
  color: $gray-800 !important;
}
.tx-indigo {
  color: $indigo !important;
}
.tx-purple {
  color: $purple !important;
}
.tx-orange {
  color: $orange !important;
}
.tx-pink {
  color: $pink !important;
}

.tx-gray-100 {
  color: $gray-100 !important;
}
.tx-gray-200 {
  color: $gray-200 !important;
}
.tx-gray-300 {
  color: $gray-300 !important;
}
.tx-gray-400 {
  color: $gray-400 !important;
}
.tx-gray-500 {
  color: $gray-500 !important;
}
.tx-gray-600 {
  color: $gray-600 !important;
}
.tx-gray-700 {
  color: $gray-700 !important;
}
.tx-gray-800 {
  color: $gray-800 !important;
}
.tx-gray-900 {
  color: $gray-900 !important;
}

// additional custom color
.tx-br-primary {
  color: $br-primary !important;
}

// text white half transparency
.tx-white-2 {
  color: rgba(#fff, 0.2) !important;
}
.tx-white-3 {
  color: rgba(#fff, 0.3) !important;
}
.tx-white-4 {
  color: rgba(#fff, 0.4) !important;
}
.tx-white-5 {
  color: rgba(#fff, 0.5) !important;
}
.tx-white-6 {
  color: rgba(#fff, 0.6) !important;
}
.tx-white-7 {
  color: rgba(#fff, 0.7) !important;
}
.tx-white-8 {
  color: rgba(#fff, 0.8) !important;
}

// Font spacing
.tx-spacing-1 {
  letter-spacing: 0.5px !important;
}
.tx-spacing-2 {
  letter-spacing: 1px !important;
}
.tx-spacing-3 {
  letter-spacing: 1.5px !important;
}
.tx-spacing-4 {
  letter-spacing: 2px !important;
}
.tx-spacing-5 {
  letter-spacing: 2.5px !important;
}
.tx-spacing-6 {
  letter-spacing: 3px !important;
}
.tx-spacing-7 {
  letter-spacing: 3.5px !important;
}
.tx-spacing-8 {
  letter-spacing: 4px !important;
}

.tx-spacing--1 {
  letter-spacing: -0.5px !important;
}
.tx-spacing--2 {
  letter-spacing: -1px !important;
}
.tx-spacing--3 {
  letter-spacing: -1.5px !important;
}
.tx-spacing--4 {
  letter-spacing: -2px !important;
}
.tx-spacing--5 {
  letter-spacing: -2.5px !important;
}
.tx-spacing--6 {
  letter-spacing: -3px !important;
}
.tx-spacing--7 {
  letter-spacing: -3.5px !important;
}
.tx-spacing--8 {
  letter-spacing: -4px !important;
}

.tx-uppercase {
  text-transform: uppercase !important;
}
.tx-lowercase {
  text-transform: lowercase !important;
}
.tx-transform-none {
  text-transform: none !important;
}

.tx-center {
  text-align: center !important;
}
.tx-right {
  text-align: right !important;
}
.tx-left {
  text-align: left !important;
}

.tx-center-force {
  text-align: center !important !important;
}
.tx-right-force {
  text-align: right !important !important;
}
.tx-left-force {
  text-align: left !important !important;
}

.tx-italic {
  font-style: italic !important;
}
.tx-style-normal {
  font-style: normal !important;
}

// Line Height
.lh-base {
  line-height: $line-height-base !important;
}
.lh-normal {
  line-height: normal !important;
}

.lh-0 {
  line-height: 0 !important;
}
.lh-1 {
  line-height: 1.1 !important;
}
.lh-2 {
  line-height: 1.2 !important;
}
.lh-3 {
  line-height: 1.3 !important;
}
.lh-4 {
  line-height: 1.4 !important;
}
.lh-5 {
  line-height: 1.5 !important;
}
.lh-6 {
  line-height: 1.6 !important;
}
.lh-7 {
  line-height: 1.7 !important;
}
.lh-8 {
  line-height: 1.8 !important;
}
.lh-9 {
  line-height: 1.9 !important;
}
.lh-10 {
  line-height: 2 !important;
}
.lh-11 {
  line-height: 2.1 !important;
}
.lh-12 {
  line-height: 2.2 !important;
}
.lh-13 {
  line-height: 2.3 !important;
}
.lh-14 {
  line-height: 2.4 !important;
}
.lh-15 {
  line-height: 2.5 !important;
}

// Vertical align
.valign-top {
  vertical-align: top !important;
}
.valign-middle {
  vertical-align: middle !important;
}
.valign-bottom {
  vertical-align: baseline !important;
}

.valign-top-force {
  vertical-align: top !important !important;
}
.valign-middle-force {
  vertical-align: middle !important !important;
}
.valign-bottom-force {
  vertical-align: baseline !important !important;
}

// Font sizes
@mixin font-size($num, $viewport: "") {
  @if $viewport == "" {
    $viewport: "-";
  } @else {
    $viewport: "-" + $viewport + "-";
  }

  .tx#{$viewport}#{$num} {
    font-size: #{$num}px !important;
  }
  .tx#{$viewport}#{$num}-force {
    font-size: #{$num}px !important !important;
  }
}

@mixin do-font-size($viewport) {
  $num: 8;
  @while $num <= 16 {
    @include font-size($num, $viewport);
    $num: $num + 1;
  }

  $num: 18;
  @while $num <= 140 {
    @include font-size($num, $viewport);
    $num: $num + 2;
  }
}

@mixin do-font-breakpoint($viewport) {
  .tx-#{$viewport}-bold {
    font-weight: 700 !important;
  }
  .tx-#{$viewport}-semibold {
    font-weight: 600 !important;
  }
  .tx-#{$viewport}-medium {
    font-weight: 500 !important;
  }
  .tx-#{$viewport}-normal {
    font-weight: 400 !important;
  }
  .tx-#{$viewport}-light {
    font-weight: 300 !important;
  }
  .tx-#{$viewport}-thin {
    font-weight: 200 !important;
  }
  .tx-#{$viewport}-xthin {
    font-weight: 100 !important;
  }

  .lh-#{$viewport}-0 {
    line-height: 0 !important;
  }
  .lh-#{$viewport}-1 {
    line-height: 1.1 !important;
  }
  .lh-#{$viewport}-2 {
    line-height: 1.2 !important;
  }
  .lh-#{$viewport}-3 {
    line-height: 1.3 !important;
  }
  .lh-#{$viewport}-4 {
    line-height: 1.4 !important;
  }
  .lh-#{$viewport}-5 {
    line-height: 1.5 !important;
  }
  .lh-#{$viewport}-6 {
    line-height: 1.6 !important;
  }
  .lh-#{$viewport}-7 {
    line-height: 1.7 !important;
  }
  .lh-#{$viewport}-8 {
    line-height: 1.8 !important;
  }
  .lh-#{$viewport}-9 {
    line-height: 1.9 !important;
  }
  .lh-#{$viewport}-10 {
    line-height: 2 !important;
  }
  .lh-#{$viewport}-11 {
    line-height: 2.1 !important;
  }
  .lh-#{$viewport}-12 {
    line-height: 2.2 !important;
  }
  .lh-#{$viewport}-13 {
    line-height: 2.3 !important;
  }
  .lh-#{$viewport}-14 {
    line-height: 2.4 !important;
  }
  .lh-#{$viewport}-15 {
    line-height: 2.5 !important;
  }

  .tx-#{$viewport}-center {
    text-align: center !important;
  }
  .tx-#{$viewport}-right {
    text-align: right !important;
  }
  .tx-#{$viewport}-left {
    text-align: left !important;
  }

  .tx-#{$viewport}-center-force {
    text-align: center !important !important;
  }
  .tx-#{$viewport}-right-force {
    text-align: right !important !important;
  }
  .tx-#{$viewport}-left-force {
    text-align: left !important !important;
  }
}

$num: 8;
@while $num <= 16 {
  @include font-size($num);
  $num: $num + 1;
}

$num: 18;
@while $num <= 140 {
  @include font-size($num);
  $num: $num + 2;
}

@media (min-width: 480px) {
  @include do-font-size("xs");
  @include do-font-breakpoint("xs");
}

@include media-breakpoint-up(sm) {
  @include do-font-size("sm");
  @include do-font-breakpoint("sm");
}

@include media-breakpoint-up(md) {
  @include do-font-size("md");
  @include do-font-breakpoint("md");
}

@include media-breakpoint-up(lg) {
  @include do-font-size("lg");
  @include do-font-breakpoint("lg");
}

@include media-breakpoint-up(xl) {
  @include do-font-size("xl");
  @include do-font-breakpoint("xl");
}
