@use "../../../../scss/variables.scss" as var;

.chartContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .label {
    padding: 8px;
    border: 3px solid;
    border-radius: 12px;
    color: #5e656e;
    position: relative;

    flex: 1;
    max-width: 220px;
    min-width: 100px;

    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    .entity {
      font-size: 12px;
      font-family: var.$font-family-archivo-semibold;
    }

    .building {
      font-size: 10px;
    }

    .property {
      font-size: 10px;
    }
  }
}

.customLegend {
  float: right;
  margin-right: 40px;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 11px;
  color: #bebebe;
}

.legendLine {
  width: 20px;
  height: 2px;
  border-bottom: 2px solid #bebebe;
}

.legendLineDashed {
  border-bottom: 2px dashed #bebebe;
}
