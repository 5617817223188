.zone-wrapper {
  display: flex;
  gap: 15px 20px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.zone-tile:empty {
  min-width: 300px;
}

.zone-tile {
  cursor: pointer;
  min-width: 300px;
  max-width: 400px;

  .card {
    transition: transform 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    border-radius: 5px;

    .left {
      width: 40%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .right {
      width: 60%;
    }
  }

  &:hover {
    .card {
      transform: translate(0, -5px);
    }
  }
}

.property-label {
  color: rgb(134, 139, 161);
}

.wrapper {
  display: flex;
  height: 92vh;

  .main {
    flex-grow: 1;
    padding-top: 5px;
    overflow-y: scroll;
    max-height: 87vh;
  }

  .side {
    min-width: 400px;
    margin-right: 20px;
  }
}

.co2-value-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .label {
    font-size: 10px;
    color: white;
    font-family: Roboto;
    letter-spacing: 0.5px;
  }

  .unit {
    font-size: 32px;
    color: white;
    font-family: Roboto;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 700px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .main {
    order: 2;
  }

  .side {
    order: 1;
    margin: 20px;
    margin-right: 30px !important;
  }
}
