@use "../../../../scss/variables.scss" as var;

#EnergyConsumptionTrackingConfig {
  width: 100%;

  .pagetitle-title {
    font-size: 20px;
  }

  hr {
    margin-bottom: 25px;
  }

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .field-status {
    font-weight: 100;
    color: #707070;
  }

  input {
    @include var.InputStyling;

    &.left-input {
      outline-color: red;
      border-width: 2px;

      &:hover {
        border-color: red;
      }
    }

    &.right-input {
      outline-color: blue;

      &:hover {
        border-color: blue;
        border-width: 2px;
      }
    }
  }

  .top-left-panel {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    .headers {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      .legend {
        color: #646464;
        display: flex;
        justify-content: flex-start;
      }
    }

    .meters-section {
      flex: 1;
    }

    .meters {
      @include var.InputStyling;

      padding: 20px;
      overflow: scroll;
      height: 125px;

      .label {
        margin-bottom: 20px;
      }

      &:hover {
        border-color: lime;
      }

      .meter-item {
        font-weight: lighter;
        display: flex;
        align-items: center;
        cursor: pointer;

        .delete-icon {
          margin-left: 10px;
        }

        &:hover {
          color: red;
          .delete-icon {
            fill: red;
          }
        }
      }
    }
  }

  .bottom-left-panel {
    margin-top: 20px;
    padding-bottom: 10px;

    .image {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        max-width: 750px;
        border-radius: 12px;
      }

      .select-left {
        width: 22%;
        height: 7%;
        border: 2px solid red;
        border-radius: 7px;
        position: absolute;
        top: 7%;
        left: 15%;
      }

      .select-right {
        width: 13.5%;
        height: 4.5%;
        border: 2px solid blue;
        border-radius: 5px;
        position: absolute;

        top: 14.5%;
        left: 18.5%;
      }

      .select-meters {
        width: 96%;
        height: 56%;
        border: 2px solid lime;
        border-radius: 7px;
        position: absolute;
        top: 33%;
        right: 2%;
      }
    }
  }

  .button-bottom-panel {
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 20px;

      .copy-button {
        @include var.SecondaryButton;
      }

      .view-button {
        @include var.PrimaryButton;
      }
    }
  }

  .symbol {
    color: red;
    margin: 0 5px;
  }
}
