.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .exit {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .modal-card {
    padding: 30px;
    max-height: 90vh;
    min-width: 50vw;
    max-width: 90vw;
    overflow-y: scroll;
    border-radius: 12px;
  }
}
