@use "../../../../scss/variables.scss" as var;

.ButtonCell {
  .buttons-wrapper {
    display: flex;
    gap: 10px;

    .button {
      padding: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;

      @include var.SecondaryButton;
      background-color: var(--buttonColor);

      border: none;
    }
  }
}
