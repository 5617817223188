#QRForEstate {
  display: block;
  position: static;
  text-align: center;
  width: 100%;

  button {
    margin-bottom: 20px;
    width: 80%;
  }

  #OPNpng {
    height: 24px;
    width: 167px;
    display: none;
  }
}
