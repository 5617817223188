@use "../../../scss/variables.scss" as var;

@mixin Label {
  font-size: 14px;
  color: #000;
  font-family: var.$font-family-archivo-semibold;
}

.mainWrapper {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.mainTitle {
  margin-bottom: 0;
}

.mainRow {
  margin-top: 25px;
  margin-bottom: 25px;
}

.metersTableContainer {
  height: 70vh;
}

.timeframePickerSection {
  max-width: 450px;
}

.organisationSelector {
  [class*="ant-select-selector"] {
    border-radius: 12px !important;
  }
}
