#Zone {
  .header {
    .col {
      display: flex;

      .title {
        color: #080808;
        font-size: 20px;
        border-radius: 12px;
        background: white;
        padding: 10px 15px 10px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon-wrapper {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .charts {
    padding-bottom: 20px;
  }
}
