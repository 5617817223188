@use "../../scss/variables.scss" as var;

.Input {
  width: 100%;
  position: relative;

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .field-status {
    font-weight: 100;
    color: #707070;
  }

  .dedicated-input {
    @include var.InputStyling;

    &:hover {
      border: 2px solid #b2b2b2 !important;
    }
  }

  .prefix {
    position: absolute;
    left: 8px;
    bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .suffix {
    position: absolute;
    right: 8px;
    bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
}
