#AdminMenuItem {
  &.menu-item-group {
    padding: 10px;
    height: auto;
    cursor: pointer;
  }

  .menu-item-group-label {
    margin: 0;
    margin-left: 5px;
    font-weight: normal;
  }

  .menu-item-building {
    margin: 10px 0 0;
  }

  .menu-item-navlink {
    padding: 5px 5px;
  }

  .menu-item-wrapper {
    transition: all 0.3s;
    padding: 0;
    margin: 0;
  }

  .menu-item-hidden {
    overflow: hidden;
    max-height: 0px;
  }

  .menu-item-expanded {
    max-height: 100vh;
  }

  .menu-item-icon {
    font-weight: 700;
    font-size: 18px;
    transition: all 0.3s;
    margin-right: 8px;
  }
}
