$font-color: #869099;
$pastel-green: #77dd77;
$pastel-red: #ff6961;
$blue-background: #003054;

@mixin max-width {
  max-width: 1560px;

  @media (min-width: 1921px) {
    max-width: 2560px;
  }

  @media (min-width: 2561px) {
    max-width: 3840px;
  }
}

@mixin tile-gap {
  gap: 10px;

  @media (min-width: 1921px) {
    gap: 15px;
  }

  @media (min-width: 2561px) {
    gap: 20px;
  }
}

@mixin title-font-size {
  letter-spacing: 0.5px;
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
    padding-left: 15px;
  }
}

@mixin name-font-size {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.7rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.3rem;
  }
}

@mixin description-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.3rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.9rem;
  }
}

@mixin value-font-size {
  font-size: 1.2rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
  }
}

@mixin medium-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 2.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 3rem;
  }
}

@mixin large-value-font-size {
  font-size: 2.2rem;

  @media (min-width: 1921px) {
    font-size: 3.8rem;
  }

  @media (min-width: 2561px) {
    font-size: 4.4rem;
  }
}

@mixin value-subtext-font-size {
  font-size: 1.2rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.6rem;
  }
}

@mixin unit-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.8rem;
  }
}

@mixin leaderboard-icon-size {
  font-size: 1rem;

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}

@mixin large-icon-size {
  border-radius: 50%;
  font-size: 4rem;
  padding: 30px;

  @media (min-width: 1921px) {
    font-size: 5rem;
    padding: 50px;
  }

  @media (min-width: 2561px) {
    font-size: 8rem;
    padding: 70px;
  }
}

@mixin header-title {
  font-size: 1.8rem;

  @media (min-width: 1921px) {
    font-size: 2.4rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.6rem;
  }
}

@mixin header-building-name {
  font-size: 2.2rem;

  @media (min-width: 1921px) {
    font-size: 2.8rem;
  }

  @media (min-width: 2561px) {
    font-size: 4rem;
  }
}

@mixin footer-clock {
  font-size: 1.75rem;

  @media (min-width: 1921px) {
    font-size: 2.35rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}

@mixin footer-powered-by {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.1rem;
  }
}

@mixin logo-img-size {
  height: 2rem;

  @media (min-width: 1921px) {
    height: 3.5rem;
  }

  @media (min-width: 2561px) {
    height: 5rem;
  }
}

@mixin box-shadow {
  box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -webkit-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
}

@mixin flex-center($direction: column) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin pulsing-bar {
  animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.55;
  }
}

#EnergyLeaderboardDashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 80vh;
  width: 80vw;
  margin: 10vh auto 10vh;
  @include tile-gap;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
    background: $blue-background;
  }

  .background-image {
    position: absolute;
    bottom: -50px;
    opacity: 0.15;
    left: -80px;
    min-width: 105%;
  }

  // MEDIA BREAKDOWNS

  @media (max-width: 1300px) {
    display: block;
    height: 100% !important;
    width: 90vw;
    max-width: 800px;
    margin: 10vh auto;

    .background {
      position: fixed !important;
    }

    .background-image {
      position: fixed !important;
    }

    .tile {
      overflow: visible;
    }

    .dashboard-title {
      max-width: 800px !important;

      .title {
        display: none;
      }

      .building-name {
        width: 100%;
        text-align: center;
      }
    }

    .dashboard-footer {
      position: static !important;
      max-width: 800px !important;

      .clock-wrapper {
        display: none !important;
      }
    }

    .leaderboard {
      height: auto;
      margin-bottom: 15px;
    }

    .intensity {
      height: 250px;
      margin-bottom: 15px;
    }

    .trivia-tile {
      height: 300px;
      margin-bottom: 15px;
    }

    .emissions-targets {
      height: 600px;
      margin-bottom: 15px;
    }

    .category-wrapper {
      flex-basis: 30px;
    }
  }

  .dashboard-title {
    position: absolute;
    top: 0vh;
    display: flex;
    justify-content: space-between;
    width: inherit;
    height: 10vh;
    color: white;
    align-items: center;
    @include max-width;

    .building-name {
      @include header-building-name;
      letter-spacing: 0.5px;
    }

    .title {
      @include header-title;
    }

    .logo {
      height: 10vh;
      margin-top: -3.5vh;
    }
  }

  .dashboard-footer {
    position: absolute;
    bottom: 0;
    height: 8vh;
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    @include max-width;

    .clock-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      background: rgba($blue-background, 0.5);
      @include footer-clock;
    }

    .powered-by {
      letter-spacing: 0.5px;
      padding: 10px;
      border-radius: 10px;
      background: rgba($blue-background, 0.5);

      h3 {
        margin: 0;
        font-weight: 400;
        @include footer-powered-by;
      }

      img {
        margin-left: 5px;
        @include logo-img-size;
      }
    }
  }

  .tile {
    border-radius: 15px;
    border: 1px solid lightgray;
    background: white !important;
    padding: 12px;
    overflow: hidden;
    @include box-shadow;

    .content-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .leaderboard {
    grid-area: 1 / 1 / 4 / 2;

    #Leaderboard {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        .title {
          @include title-font-size;
        }
      }

      .body {
        height: 100%;
        // overflow: hidden;
        display: flex;
        flex-direction: column;

        .pie-container {
          overflow: visible !important;
        }

        .summary {
          display: flex;
          flex-grow: 1;
          flex-direction: column;

          .category-wrapper {
            display: flex;
            flex: 1;

            .icon-wrapper {
              @include flex-center;
              margin-right: 20px;

              .icon {
                height: 60%;
                min-width: 40px;
                border-radius: 5px;
                @include flex-center;
                @include box-shadow;
              }
            }

            .name {
              @include flex-center;
              @include name-font-size;
              white-space: nowrap;
              justify-content: center;
              align-items: flex-start;
            }

            .value {
              margin-left: auto;
              @include flex-center;
              @include description-font-size;
            }

            .percent {
              @include flex-center;
              @include value-font-size;
              flex: 1;
            }
          }
        }
      }
    }
  }

  .intensity {
    grid-area: 1 / 2 / 2 / 3;
    background: blue;

    #Intensity {
      height: 100%;

      #intensity-carousel {
        height: 100%;

        .carousel-indicators {
          position: relative;
          margin-left: auto;
          margin-right: 0px;
          bottom: -5px;
          float: right;

          li {
            border-radius: 50%;
            background-color: $font-color;
          }
        }

        .carousel-inner {
          height: 100%;
          position: absolute;
          top: 5px;

          .carousel-item {
            height: 100%;

            .per-space-wrapper,
            .per-person-wrapper {
              height: 100%;

              .header {
                .title {
                  @include title-font-size;
                }
              }

              .per-person-body,
              .per-space-body {
                height: 80%;

                @include flex-center(row);

                .benchmark,
                .this-period {
                  flex: 1;
                  @include flex-center;

                  .label {
                    @include value-subtext-font-size;
                  }

                  .value {
                    @include large-value-font-size;
                  }

                  .subtext {
                    @include value-subtext-font-size;
                    @include flex-center(row);
                  }
                }

                .icon {
                  border-radius: 50%;
                  padding: 20px;
                  display: flex;
                  flex: 1;
                  @include flex-center;
                }
              }
            }
          }
        }
      }
    }
  }

  .trivia-tile {
    grid-area: 1 / 3 / 2 / 4;

    .stretch {
      grid-area: 1 / 2 / 1 / 4 !important;
    }
  }

  .emissions-targets {
    grid-area: 2 / 2 / 4 / 4;

    #EmissionsTargets {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        .title {
          @include title-font-size;
        }
      }

      .body {
        height: 100%;

        .highcharts-bar-series rect:nth-last-child(2) {
          @include pulsing-bar;
        }

        .meter-wrapper {
          display: flex;
          height: 20%;
          max-height: 20%;

          .icon-wrapper {
            height: 100%;
            width: 100%;
            @include flex-center;
            flex: 1;

            .icon {
              border-radius: 5px;
              height: 50%;
              width: 70%;
              min-width: 60px;
              @include flex-center;
              @include box-shadow;
            }
          }

          .text-wrapper {
            width: 100%;
            border-bottom: 1px solid lightgray;
            @include flex-center();
            align-items: flex-start;
            flex: 3;
            padding: 3%;
            overflow: hidden;
            height: 100%;

            .name {
              @include name-font-size;
            }

            .description {
              @include description-font-size;
              width: 100%;
              line-height: 130%;
            }
          }

          .value-wrapper {
            @include flex-center;
            min-width: 90px;
            flex: 1;

            .value {
              @include value-font-size;
            }

            .unit {
              @include unit-font-size;
            }
          }
        }
      }
    }
  }
}
