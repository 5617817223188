@use "../../scss/variables.scss" as var;

.DashboardCard {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 340px;
  flex: 1 0 270px;
  border-radius: 12px;
  padding: 25px;
  cursor: pointer;
  transition: transform 0.1s linear;

  &.hoverable:hover {
    transform: scale(1.02);
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.21);
  }

  &.empty {
    opacity: 0;
    height: 0;
  }

  &.disabled {
    opacity: 1;
    box-shadow: none;
    cursor: default;
  }

  .disabled-overlay {
    position: absolute;
    height: calc(100% + 0px);
    width: calc(100% + 0px);
    background: #d6d6d6f0;
    border-radius: 11px;
    top: -0px;
    left: -0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 30px;
    font-size: 20px;
    color: #343a40;
    font-family: var.$font-family-archivo-semibold;

    .belt {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 20px;
    }
  }

  .picture {
    border-radius: 6px;
    height: 150px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      border: 1px solid #d6d6d6;
      border-radius: 12px;
      object-fit: cover;

      height: 150px;
      width: 100%;
    }
  }

  .title {
    font-size: 16px;
    font-family: var.$font-family-archivo-semibold;
    color: #343a40;
    min-height: 55px;
    display: flex;
    align-items: center;
  }

  .description {
    font-size: 14px;
  }
}
