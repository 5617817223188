@use "../../../../scss/variables.scss" as var;

#EnvironmentalConditionsConfig {
  width: 100%;

  .pagetitle-title {
    font-size: 20px;
  }

  hr {
    margin-bottom: 25px;
  }

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .field-status {
    font-weight: 100;
    color: #707070;
  }

  .BuildingSelect {
    [class$="control"] {
      &:hover {
        border: 2px solid red;
      }
    }
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;

    .building-info {
      @include var.InputStyling;
      height: 129px;
      display: flex;
      flex-direction: column;
    }
  }

  .right-panel {
    padding-bottom: 10px;

    .image {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        max-width: 750px;
        border-radius: 12px;
      }

      .select-left {
        width: 99%;
        height: 93%;
        border: 2px solid red;
        border-radius: 7px;
        position: absolute;
        top: 0.5%;
        left: 1%;
      }
    }
  }

  .button-bottom-panel {
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 20px;

      .copy-button {
        @include var.SecondaryButton;
      }

      .view-button {
        @include var.PrimaryButton;
      }
    }
  }

  .legend {
    color: #646464;
    display: flex;
    justify-content: flex-start;
  }

  .symbol {
    color: red;
    margin: 0 5px;
  }
}
