@use "../../scss/variables.scss" as var;

.Chart {
  width: 100%;
  background: white;
  border-radius: 12px !important;
  padding: 25px 25px 10px 25px;

  .chart-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    .title {
      font-size: 18px;
      color: #080808;
    }

    .buttons {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      .time-bucket {
        @include var.GreyAndBlackButton;
      }
    }
  }

  &.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}
