
.spinner {
  margin: 100px auto;
  width: 80px;
  height: 90px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #2d405b83;
  height: 100%;
  width: 12px;
  display: inline-block;
  margin:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.mini-spinner {
  position: absolute;
  left: -1.6rem;
  top: 0.2rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid #17a2b8;
  border-bottom: 0.25rem solid rgba(0,0,0,0);
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @media (max-width: 850px) {
    border: 0.25rem solid #ababab;
    border-bottom: 0.25rem solid rgba(0,0,0,0);
    left: unset;
    right: 1rem;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}