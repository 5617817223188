#DisclaimerModal {
    position: fixed;
    background-color: rgba(0,0,0,.85);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .disclaimer-card {
        padding: 20px 20px;
        max-height: 90vh;
        max-width: 70vw;
        overflow-Y: scroll;
        background: white;
        border-radius: 5px;

        .modal-content {
            color: #818AC3;
            border: none;

            .title {
                font-size: 20px;
                color: #343a40;
                margin-bottom: 20px;
                font-weight: 500;
    
            }
    
            .text {
                font-size: 16px;
                color: #343a40;
                margin-bottom: 20px;
    
            }
        }

        .disclaimer-buttons {
            text-align: center;

            .okay-button {
                border-radius: 20px;
                font-size: 16px;
                margin-left: auto;
            }
        }


    }
}