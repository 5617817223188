@use "../../scss/variables.scss" as var;

.wrapper {
  height: 100%;

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 30px;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }

    .inputFilter {
      max-width: 300px;
      border-radius: 12px;
      padding: 5px 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .tagFilters {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      .tag {
        @include var.GreyAndBlackButton;
      }
    }
  }
  .table {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    &.clickable {
      [class^="ant-table-row"] {
        cursor: pointer !important;
      }
    }

    &.nonScrollable {
      [class*="ant-table-tbody-virtual-scrollbar-horizontal"] {
        display: none;
      }
    }
  }
}
