@use "../../../scss/variables.scss" as var;

$menu-width: 250px;
$background-color: #194a5f;
$font-color: var.$off-white;

.NetZeroPlanning {
  &.outOfLogin {
    left: 0 !important;
  }

  .placeholder {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .contextualMenu {
    position: fixed;
    top: var.$br-header-height;
    left: var.$br-sideleft-width;
    bottom: 0px;
    width: $menu-width;
    background: $background-color;
    padding: 20px 0;
    border-top-left-radius: 0px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    transition: left 0.2s ease-in-out, transform 0.2s ease-in-out;
    z-index: 3;

    &.closed {
      transform: translateX(-$menu-width);
    }

    .label {
      color: $font-color;
      font-size: 18px;
      font-family: var.$font-family-archivo-semibold;
      padding: 0 20px;
    }

    .organisationSelector {
      padding: 0 20px;
      margin-top: 20px;
    }

    .planButtons {
      height: 40px;
      display: flex;
      margin-top: 10px;
      width: 100%;
      padding: 0 20px;

      flex-direction: row;
      gap: 15px;

      button {
        flex: 1;
      }
    }

    .plansList {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      padding: 0 10px;

      flex-grow: 1;
      overflow: scroll;
      gap: 5px;

      .reportItem {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 10px;
        border-radius: 12px;
        cursor: pointer;

        &.highlighted {
          background: linear-gradient(to right, #17a2b8 0%, #1caf9a 100%);
        }

        &:not(.highlighted):hover {
          background: linear-gradient(to right, #0d5d69 0%, #106659 100%);
        }

        .reportName {
          font-size: 14px;
          color: $font-color;
          cursor: pointer;
        }
        .reportDescription {
          font-size: 12px;
          color: $font-color;
          color: lightgray;
          cursor: pointer;
        }

        .reportDelete {
          cursor: pointer;
          min-width: 65px;
          display: flex;
          justify-content: flex-end;

          button {
            padding: 15px;
            background: #b53737;
            border: none;
            outline: none;
            border-radius: 50%;
            transition: none;

            &:hover {
              background: #d04b4b;
            }
          }
        }
      }

      .noReports {
        color: $font-color;
        font-size: 14px;
        padding: 10px;
        width: 100%;
        text-align: center;
        opacity: 0.2;
      }
    }

    .menuButton {
      background: #194a5f;
      border: none;
      margin-left: auto;
      position: absolute;
      top: 20px;
      border-radius: 0 5px 5px 0;
      right: -20px;
      height: 30px;
      margin: 0;

      &:hover {
        background: #194a5f;
      }
    }
  }

  .mainWrapper {
    margin-left: $menu-width;
    min-height: calc(100vh - 80px);
    overflow: scroll;
    transition: margin-left 0.2s ease-in-out;
    padding: 20px 40px;

    @media (max-width: 1200px) {
      padding: 20px 20px;
    }

    &.menuClosed {
      margin-left: 0;
    }
  }

  .mainTitle {
    margin-bottom: 0;
  }

  .timeframe {
    margin: 5px 0 !important;
  }

  .stepsContent {
    padding-top: 0px;
    min-height: 200px;
  }

  .explanation {
    padding: 10px 50px;
  }

  @media (max-width: 1200px) {
    .mainWrapper {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 991px) {
    .contextualMenu {
      left: 0px !important;
      border-top-left-radius: 0;
    }
  }
}


.actionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 16px;
  min-height: 20vh;
  overflow: scroll;
  
  .actionCard {
      height: 100%;
      border: 5px solid #e8e8e8;
      
      .actionName {
          text-align: center;
          font-weight: 500;
          min-height: 40px; // Ensures consistent height for name row
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .actionValue {
          text-align: center;
          font-size: 1.2em;
          font-weight: 600;
          margin: 12px 0;
          min-height: 30px; // Ensures consistent height for value row
          color: inherit;
      }
      
      .actionButtons {
          display: flex;
          justify-content: center;
          min-height: 32px; // Ensures consistent height for buttons row
      }

      &:hover {
        border-color: #1caf9a;
      }
  }
}
