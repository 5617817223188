$font-color: #869099;
$pastel-green: #77dd77;
$pastel-red: #ff6961;
$blue-background: #003054;

@mixin max-width {
  max-width: 1560px;

  @media (min-width: 1921px) {
    max-width: 2560px;
  }

  @media (min-width: 2561px) {
    max-width: 3840px;
  }
}
@mixin tile-gap {
  gap: 10px;

  @media (min-width: 1921px) {
    gap: 15px;
  }

  @media (min-width: 2561px) {
    gap: 20px;
  }
}
@mixin title-font-size {
  letter-spacing: 0.5px;
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
    padding-left: 15px;
  }
}
@mixin name-font-size {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.7rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.3rem;
  }
}
@mixin description-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.3rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.9rem;
  }
}
@mixin value-font-size {
  font-size: 1.2rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
  }
}
@mixin medium-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 2.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 3rem;
  }
}
@mixin large-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 3.5rem;
  }

  @media (min-width: 2561px) {
    font-size: 4.1rem;
  }
}
@mixin value-subtext-font-size {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.2rem;
  }
}
@mixin unit-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.8rem;
  }
}
@mixin leaderboard-icon-size {
  font-size: 2rem;

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}
@mixin large-icon-size {
  border-radius: 50%;
  font-size: 4rem;
  padding: 30px;

  @media (min-width: 1921px) {
    font-size: 5rem;
    padding: 50px;
  }

  @media (min-width: 2561px) {
    font-size: 8rem;
    padding: 70px;
  }
}
@mixin header-title {
  font-size: 1.8rem;

  @media (min-width: 1921px) {
    font-size: 2.4rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.6rem;
  }
}
@mixin header-building-name {
  font-size: 2.2rem;

  @media (min-width: 1921px) {
    font-size: 2.8rem;
  }

  @media (min-width: 2561px) {
    font-size: 4rem;
  }
}
@mixin footer-clock {
  font-size: 1.75rem;

  @media (min-width: 1921px) {
    font-size: 2.35rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}
@mixin footer-powered-by {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.1rem;
  }
}
@mixin logo-img-size {
  height: 2rem;

  @media (min-width: 1921px) {
    height: 3.5rem;
  }

  @media (min-width: 2561px) {
    height: 5rem;
  }
}
@mixin box-shadow {
  box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -webkit-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
}
@mixin flex-center($direction: column) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
@mixin pulsing-bar {
  animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.55;
  }
}

#MeterUsageDashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 80vh;
  width: 80vw;
  margin: 10vh auto;
  @include tile-gap;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
    background: $blue-background;
  }

  .background-image {
    position: absolute;
    bottom: -50px;
    opacity: 0.15;
    left: -80px;
    min-width: 105%;
  }

  // MEDIA BREAKDOWNS

  @media (max-width: 1300px) {
    display: block;
    height: 100% !important;
    width: 90vw;
    max-width: 800px;
    margin: 10vh auto;

    .background {
      position: fixed !important;
    }
    .background-image {
      position: fixed !important;
    }

    .tile {
      overflow: visible;
    }

    .dashboard-title {
      max-width: 800px !important;

      .title {
        display: none;
      }

      .building-name {
        width: 100%;
        text-align: center;
      }
    }

    .dashboard-footer {
      position: static !important;
      max-width: 800px !important;

      .clock-wrapper {
        display: none !important;
      }
    }

    .emissions-by-category {
      height: auto;
      margin-bottom: 15px;
    }

    .intensity {
      height: 250px;
      margin-bottom: 15px;
    }

    .trend-vs-last-week {
      height: 250px;
      margin-bottom: 15px;
    }

    .today-so-far {
      height: 300px;
      margin-bottom: 15px;
    }

    .trivia-tile {
      height: 300px;
      margin-bottom: 15px;

      .image {
        img {
        }
      }
    }

    .heaviest-emitters-leaderboard {
      height: 600px;
      margin-bottom: 15px;
    }

    .chart-usage-vs-last-week {
      height: 600px;
      margin-bottom: 15px;
    }
  }

  .dashboard-title {
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-between;
    width: inherit;
    color: white;
    align-items: center;
    @include max-width;

    .building-name {
      @include header-building-name;
      letter-spacing: 0.5px;
    }
    .title {
      @include header-title;
    }
    .logo {
      height: 10vh;
      margin-top: -3.5vh;
    }
  }
  .dashboard-footer {
    position: absolute;
    bottom: 0;
    height: 8vh;
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    @include max-width;

    .clock-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      background: rgba($blue-background, 0.5);
      @include footer-clock;
    }

    .powered-by {
      letter-spacing: 0.5px;
      padding: 10px;
      border-radius: 10px;
      background: rgba($blue-background, 0.5);

      h3 {
        margin: 0;
        font-weight: 400;
        @include footer-powered-by;
      }

      img {
        margin-left: 5px;
        @include logo-img-size;
      }
    }
  }
  .tile {
    border-radius: 15px;
    border: 1px solid lightgray;
    background: white !important;
    padding: 15px;
    overflow: hidden;
    @include box-shadow;

    .content-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .trivia-tile {
    grid-area: 1 / 3 / 1 / 4;

    #TriviaTile {
      height: 100%;

      #trivia-carousel {
        height: 100%;

        .carousel-indicators {
          position: relative;
          margin-left: auto;
          margin-right: 0px;
          bottom: -5px;
          float: right;

          li {
            border-radius: 50%;
            background-color: $font-color;
          }
        }

        .carousel-inner {
          height: 100%;
          position: absolute;
          top: 5px;

          .carousel-item {
            height: 100%;

            .trivia {
              height: 100%;
              display: flex;
              flex-direction: column;

              .header {
                .title {
                  @include title-font-size;
                }
              }

              .body {
                display: flex;
                flex: 1;
                max-height: 85%;

                .text {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  font-size: 0.95rem;
                  padding: 10px;

                  .value {
                    text-align: center;
                    @include large-value-font-size;
                  }
                  .smaller-value {
                    text-align: center;
                    @include medium-value-font-size;
                  }
                  .label {
                    text-align: center;
                    @include value-subtext-font-size;
                  }
                }
                .image {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    min-width: 150px;
                    max-height: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .today-so-far {
    grid-area: 1 / 2 / 1 / 2;

    #TodaySoFar {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        .title {
          @include title-font-size;
        }
      }

      .body {
        height: 100%;
        width: 90%;
        margin: auto;
        position: relative;

        .labels {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .label {
            white-space: nowrap;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 1px;
            margin-top: 10px;
          }

          .unit {
            white-space: nowrap;
            font-size: 25px;
            letter-spacing: 1px;
            line-height: 1.2;

            .suffix {
              display: flex;
              flex-direction: column;

              .kwh {
                font-size: 13px;
                font-weight: 400;
              }
            }
          }

          .current {
            position: absolute;
            top: 0;
            left: 0;
          }

          .percentage {
            position: absolute;
            bottom: 10px;
            width: 100%;
            display: flex;
            justify-content: center;

            .unit {
              display: flex;
              align-items: center;

              .percent {
                font-size: 15px;
                font-weight: 400;
              }
            }
          }

          .goal {
            position: absolute;
            top: 0;
            right: 0;

            .unit,
            .suffix {
              float: right;
            }
          }
        }
      }
    }
  }
  .trend-vs-last-week {
    grid-area: 1 / 1 / 1 / 2;

    #TrendVsLastWeek {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .header {
        .title {
          @include title-font-size;
        }
      }

      .body {
        display: flex;
        height: 100%;

        .text-panel {
          height: 100%;
          width: 60%;
          @include flex-center;

          .value {
            @include large-value-font-size;
          }
          .value-subtext {
            @include value-subtext-font-size;
          }
        }

        .icon-panel {
          height: 100%;
          width: 40%;
          @include flex-center;

          .icon-wrapper {
            display: flex;

            &.red {
            }

            &.green {
            }
          }
        }
      }
    }
  }
  .chart-usage-vs-last-week {
    grid-area: 2 / 1 / 4 / 4;

    #UsageChart {
      height: 100%;
      display: flex;
      flex-direction: column;
      .header {
        .title {
          @include title-font-size;
        }
      }

      .body {
        height: 100%;
        display: flex;
        flex-direction: column;

        .chart-container {
          height: 100%;
        }

        &.Monday {
          .highcharts-series-0 rect:nth-of-type(1) {
            @include pulsing-bar;
          }
        }
        &.Tuesday {
          .highcharts-series-0 rect:nth-of-type(2) {
            @include pulsing-bar;
          }
        }
        &.Wednesday {
          .highcharts-series-0 rect:nth-of-type(3) {
            @include pulsing-bar;
          }
        }
        &.Thursday {
          .highcharts-series-0 rect:nth-of-type(4) {
            @include pulsing-bar;
          }
        }
        &.Friday {
          .highcharts-series-0 rect:nth-of-type(5) {
            @include pulsing-bar;
          }
        }
        &.Saturday {
          .highcharts-series-0 rect:nth-of-type(6) {
            @include pulsing-bar;
          }
        }
        &.Sunday {
          .highcharts-series-0 rect:nth-of-type(7) {
            @include pulsing-bar;
          }
        }
      }
    }
  }
}
