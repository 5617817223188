.main-wrapper {
  width: 100%;

  .title-wrapper {
    font-size: 100px;
    padding: 10px 0;

    @media (max-width: 1440px) {
      font-size: 72px;
    }
    @media (max-width: 768px) {
      font-size: 60px;
    }
    @media (max-width: 500px) {
      font-size: 40px;
      padding: 0;
    }
    @media (max-width: 325px) {
      font-size: 30px;
      padding: 0;
    }
  }
  .secondary-title-wrapper {
    font-size: 50px;

    @media (max-width: 1440px) {
      font-size: 35px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
    }
    @media (max-width: 325px) {
      font-size: 18px;
      padding: 0;
    }
  }

  p {
    font-size: 18px;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
    }
    @media (max-width: 500px) {
      font-size: 13px;
      margin: 20px 0 20px 0;
    }
  }


}


.signin-logo {
  transition: all 0.5s ease;

  &:hover {

    .opn {
      background-image: repeating-linear-gradient(
        135deg,
        #fad201,
        #dea731 15px,
        #343a40 15px,
        #343a40 30px
      );
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .left-bracket {
      margin-right: 10px;
    }

    .right-bracket {
      margin-left: 10px;
    }

    .left-bracket,
    .right-bracket {
      transition: all .3s ease;
      background-image: repeating-linear-gradient(
        135deg,
        #fad201,
        #dea731 15px,
        #343a40 15px,
        #343a40 30px
      );
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: bolder;
    }

  }

  .left-bracket,
  .right-bracket {
    transition: all .3s ease;
    margin: 0 -5px;
    background-image: repeating-linear-gradient(
      135deg,
      #343a40,
      #343a40 10px,
      #343a40 10px,
      #343a40 20px
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}
