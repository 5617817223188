@mixin title-font-size {
  letter-spacing: 0.5px;
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
    padding-left: 15px;
  }
}

@mixin large-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 3.5rem;
  }

  @media (min-width: 2561px) {
    font-size: 4.1rem;
  }
}

@mixin medium-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 2.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 3rem;
  }
}

@mixin value-subtext-font-size {
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.7rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.4rem;
  }
}

.DashboardSlideshow {
  height: 100%;

  div[id^="dashboard-carousel"] {
    height: 100%;

    .carousel-indicators {
      position: relative;
      margin-left: auto;
      margin-right: 0px;
      bottom: -5px;
      float: right;

      li {
        border-radius: 50%;
        background-color: #869099;
      }
    }

    .carousel-inner {
      height: 100%;
      position: absolute;

      .carousel-item {
        height: 100%;

        .trivia {
          height: 100%;
          display: flex;
          flex-direction: column;

          .header {
            .title {
              @include title-font-size;
            }
          }

          .body {
            display: flex;
            flex: 1;
            height: 85%;

            .text {
              height: 100%;
              flex: 4;
              display: flex;
              justify-content: space-evenly;
              flex-direction: column;
              padding: 0 20px;

              .value {
                text-align: center;
                @include large-value-font-size;
              }

              .smaller-value {
                text-align: center;
                @include medium-value-font-size;
              }

              .label {
                text-align: center;
                @include value-subtext-font-size;
              }
            }

            .image {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-evenly;

              img {
                height: 90%;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
