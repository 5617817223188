@use "../../../scss/variables.scss" as var;

$font-color: #869099;
$background-color: #e6e1da;

@mixin max-width {
  max-width: 1560px;

  @media (min-width: 1921px) {
    max-width: 2560px;
  }

  @media (min-width: 2561px) {
    max-width: 3840px;
  }
}
@mixin tile-gap {
  gap: 10px;

  @media (min-width: 1921px) {
    gap: 15px;
  }

  @media (min-width: 2561px) {
    gap: 20px;
  }
}
@mixin title-font-size {
  letter-spacing: 0.5px;
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
    padding-left: 15px;
  }
}
@mixin name-font-size {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.7rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.3rem;
  }
}
@mixin description-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.3rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.9rem;
  }
}
@mixin value-font-size {
  font-size: 1.2rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.5rem;
  }
}
@mixin medium-value-font-size {
  font-size: 2rem;

  @media (min-width: 1921px) {
    font-size: 2.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 3rem;
  }
}
@mixin large-value-font-size {
  font-size: 2.7rem;

  @media (min-width: 1921px) {
    font-size: 3.5rem;
  }

  @media (min-width: 2561px) {
    font-size: 4.1rem;
  }
}
@mixin value-subtext-font-size {
  font-size: 1.1rem;

  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }

  @media (min-width: 2561px) {
    font-size: 2.2rem;
  }
}
@mixin unit-font-size {
  font-size: 0.8rem;

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }

  @media (min-width: 2561px) {
    font-size: 1.8rem;
  }
}
@mixin leaderboard-icon-size {
  font-size: 2rem;

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}
@mixin large-icon-size {
  font-size: 10rem;

  @media (min-width: 1921px) {
    font-size: 5rem;
    padding: 50px;
  }

  @media (min-width: 2561px) {
    font-size: 8rem;
    padding: 70px;
  }
}
@mixin header-title {
  font-size: 1.8rem;

  @media (min-width: 1921px) {
    font-size: 2.4rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.6rem;
  }
}
@mixin header-building-name {
  font-size: 2.2rem;

  @media (min-width: 1921px) {
    font-size: 2.8rem;
  }

  @media (min-width: 2561px) {
    font-size: 4rem;
  }
}
@mixin footer-clock {
  font-size: 1.75rem;

  @media (min-width: 1921px) {
    font-size: 2.35rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.5rem;
  }
}
@mixin footer-powered-by {
  font-size: 1rem;

  @media (min-width: 1921px) {
    font-size: 1.9rem;
  }

  @media (min-width: 2561px) {
    font-size: 3.1rem;
  }
}

@mixin logo-img-size {
  height: 3rem;

  @media (min-width: 1921px) {
    height: 3.5rem;
  }

  @media (min-width: 2561px) {
    height: 5rem;
  }
}
@mixin box-shadow {
  box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
  -webkit-box-shadow: 8px 8px 10px -12px rgba(66, 68, 90, 1);
}
@mixin flex-center($direction: column) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
@mixin pulsing-bar {
  animation: pulsing-bar 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulsing-bar {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.65;
  }
}

#MetersUsageDashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  padding: 4vh 0 2vh;

  .main-wrapper {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 80vw;
    @include tile-gap;
  }

  @media (max-width: 1300px) {
    height: auto;

    .main-wrapper {
      display: block;
      height: auto !important;
      width: 90vw;
      max-width: 800px;
      margin: 0vh auto;
    }

    .background {
      position: fixed !important;
    }
    .background-image {
      position: fixed !important;
    }

    .tile {
      overflow: visible;
    }

    .dashboard-footer {
      position: static !important;
      max-width: 800px !important;
      flex-direction: column;
    }

    .headers {
      margin-bottom: 15px;
    }

    .trend-vs-last-week {
      height: 250px;
      margin-bottom: 15px;
    }

    .chart-usage-vs-last-week {
      height: 600px;
      margin-bottom: 15px;
    }
  }

  .dashboard-footer {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0b2f3f;
    @include max-width;

    .clock-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      background: rgba(var.$light-beige-grey, 0.7);
      @include footer-clock;
    }

    .powered-by {
      letter-spacing: 0.5px;
      padding: 10px;
      border-radius: 10px;
      background: rgba(var.$light-beige-grey, 0.7);
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      h3 {
        margin: 0;
        font-weight: 400;
        margin-bottom: 9px;
        @include footer-powered-by;
      }

      img {
        @include logo-img-size;
      }
    }
  }

  .tile {
    border-radius: 12px;
    border: 1px solid lightgray;
    background: white !important;
    padding: 15px;
    overflow: hidden;
    @include box-shadow;
  }

  .content-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .headers {
    grid-area: 1 / 1 / 2 / 3;
    color: #0b2f3f;

    #Headers {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      .top-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          @include large-value-font-size;
          font-size: 3rem;
          // background: rgba($lighter-beige-grey, 0.7);
          margin: 0;
          text-align: center;
        }

        .description {
          @include footer-clock;
          font-size: 1.5rem;
          text-align: center;
          // background: rgba($lighter-beige-grey, 0.7);
        }
      }

      .last-updated {
        @include value-subtext-font-size;
        font-size: 1rem;
        // background: rgba($lighter-beige-grey, 0.7);
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .trend-vs-last-week {
    grid-area: 1 / 3 / 2 / 5;

    #TrendVsLastWeek {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .header {
        .title {
          @include title-font-size;
          color: #343a40;
        }
      }

      .body {
        display: flex;
        height: 100%;
        justify-content: space-around;

        .text-panel {
          height: 100%;
          @include flex-center;

          .value {
            @include large-value-font-size;
            color: #343a40;
          }
          .value-subtext {
            @include value-subtext-font-size;
          }
        }

        .icon-panel {
          height: 100%;
          @include flex-center;

          .icon-wrapper {
            display: flex;
            margin-bottom: 20px;

            &.red {
            }

            &.green {
            }
          }
        }
      }
    }
  }

  .chart-usage-vs-last-week {
    grid-area: 2 / 1 / 4 / 5;

    #UsageChart {
      height: 100%;
      display: flex;
      flex-direction: column;
      .header {
        .title {
          @include title-font-size;
          color: #343a40;
        }
      }

      .body {
        height: 100%;
        display: flex;
        flex-direction: column;

        .chart-container {
          height: 100%;
        }

        &.Monday {
          .highcharts-series-0 rect:nth-of-type(1) {
            @include pulsing-bar;
          }
        }
        &.Tuesday {
          .highcharts-series-0 rect:nth-of-type(2) {
            @include pulsing-bar;
          }
        }
        &.Wednesday {
          .highcharts-series-0 rect:nth-of-type(3) {
            @include pulsing-bar;
          }
        }
        &.Thursday {
          .highcharts-series-0 rect:nth-of-type(4) {
            @include pulsing-bar;
          }
        }
        &.Friday {
          .highcharts-series-0 rect:nth-of-type(5) {
            @include pulsing-bar;
          }
        }
        &.Saturday {
          .highcharts-series-0 rect:nth-of-type(6) {
            @include pulsing-bar;
          }
        }
        &.Sunday {
          .highcharts-series-0 rect:nth-of-type(7) {
            @include pulsing-bar;
          }
        }
      }
    }
  }
}
