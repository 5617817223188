/* ------------------------------------------ */
/* ############### 3.1 Alerts ############### */
/* ------------------------------------------ */

// Base Alerts
.alert-success {
  @include alert-variant(lighten(theme-color('success'), 45%), theme-color('success'), darken(theme-color('success'), 8%));
}

.alert-info {
  @include alert-variant(lighten(theme-color('info'), 45%), theme-color('info'), darken(theme-color('info'), 8%));
}

.alert-warning {
  @include alert-variant(lighten(theme-color('warning'), 36%), theme-color('warning'), darken(theme-color('warning'), 12%));
}

.alert-danger {
  @include alert-variant(lighten(theme-color('danger'), 36%), theme-color('danger'), darken(theme-color('danger'), 12%));
}

.alert {
  border-width: 0;
  padding: 15px 20px;
  position: relative;

  .close { opacity: .25; }
}

.alert-bordered {
  border-width: 1px;

  &.alert-success { background-color: lighten($state-success-bg, 18%); }
  &.alert-info { background-color: lighten($state-info-bg, 18%); }
  &.alert-warning { background-color: lighten($state-warning-bg, 14%); }
  &.alert-danger { background-color: lighten($state-danger-bg, 17%); }
}

.alert-solid {
  border-color: transparent;
  color: #fff;

  &.alert-success { background-color: theme-color('success'); }
  &.alert-info { background-color: theme-color('info'); }
  &.alert-warning { background-color: theme-color('warning'); }
  &.alert-danger { background-color: theme-color('danger'); }
  &.alert-inverse { background-color: $gray-800; }
}

.alert-icon {
  opacity: .95;
  line-height: 0;
  margin-right: 10px;
  position: relative;
}

.alert-outline {
  background-color: transparent;

  .alert-icon {
    float: left;
    line-height: 0;
    margin-right: 15px;
  }

  &.alert-success {
    border: 1px solid theme-color('success');
  }
}

/* ########## RTL SUPPORT ########## */
.rtl {
  .alert-icon {
    margin-right: 0;
    margin-left: 10px;
  }
}
