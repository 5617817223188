#InventoryProcessing {
  .results-box {
    min-height: 200px;
    border: 1px solid #ced4da;
    border-radius: 5px;

    .no-results {
      opacity: 0.6;
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .results {
      color: black;
      padding: 10px;
      width: 100%;
      min-height: 200px;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: gray;
      }

      .title {
        font-weight: 500;
        letter-spacing: 0.5px;
        color: green;
      }

      .value {
        margin: 5px;
      }
    }
  }

  .link-row {
    display: flex;

    a {
      text-decoration: underline;
    }
  }
}
