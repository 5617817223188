@use "../../scss/variables.scss" as var;

.configName {
    background: #f3f3f3;
    padding: 5px 15px;
    border-radius: 12px;

    font-size: 20px;
    color: #000;
    margin-bottom: 0;
    font-family: var.$font-family-archivo-semibold;
    align-items: center;
    display: inline-flex;

    button {
      background: #f3f3f3;
      box-shadow: none;
      border: 1px solid #bebebe;
    }
  }