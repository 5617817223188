#PointDebugComponent {
  .property-menu {
    background: white;
    border-right: 1px solid #d4d4d4;
    height: 100%;
    overflow: scroll;

    .property-item {
      cursor: pointer;
      margin-bottom: 20px;
      color: #343a40;
      font-weight: 400;

      &.selected {
        text-decoration: underline;
      }

      &.changed {
        color: green;
      }
    }
  }

  .modal-controls {
    font-size: 12px;
    margin-right: 50px;
    margin-top: 10px;

    .form-group-label {
      margin-bottom: 0px;
    }

    label,
    input {
      cursor: pointer;
    }
  }

  .main-table {
    .labels {
      .property-and-unit-name {
        color: #343a40;
      }

      .mappings-wrapper {
        display: flex;
        gap: 10px;
      }

      .current-mapping-name {
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;

        display: flex;
        align-items: center;
      }
    }
  }

  .chart-wrapper {
    position: relative;

    .close-button {
      color: white;
      padding: 3px 10px;
      border-radius: 12px;
      display: inline-block;
      background: #bbb;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: gray;
  }

  .selected-row {
    background: #f2f2f2;
    text-decoration: underline;
  }
}
