@use "../../scss/variables.scss" as var;

$menu-width: 400px;
$background-color: #194a5f;
$font-color: var.$off-white;
$black-font-color: #343a40;
$separator-color: linear-gradient(to right, #1caf9a 0%, #17a2b8 100%);
$separator-color-vertical: linear-gradient(to bottom, #1caf9a 0%, #17a2b8 100%);
$gray-background: #e9ecef;
$comparison-highlight: #e9f5f7;
$box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);

$small-font: 13px;

$element-margin: 10px;
$section-margin: 20px;

@mixin label {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

#MetersExplorer {
  color: $font-color;
  transition: left 0.2s ease-in-out;

  .contextual-menu {
    position: fixed;
    top: 80px;
    left: 230px;
    bottom: 0px;
    width: $menu-width;
    background: $background-color;
    padding: 20px 20px;
    border-top-left-radius: 0px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    transition: left 0.2s ease-in-out;
    z-index: 2;

    &.left-panel-closed {
      left: 60px;

      .collapse-button {
        right: -20px;
      }
    }

    &.closed {
      left: -$menu-width;

      .collapse-button {
        right: -245px;
        background: #0a2f41;
      }
    }

    &.closed.left-panel-closed {
      .collapse-button {
        right: -75px;
      }
    }

    .timeframe-selector {
      margin-bottom: $section-margin;

      .timeframes-wrapper {
        display: flex;
        gap: 10px;

        .timeframe {
          flex: 1;

          .label {
            @include label;
            margin-bottom: $element-margin;
          }

          .selector {
            margin-bottom: $element-margin;
            .input-group-prepend {
              display: none;
            }

            .form-control {
              border-radius: 10px;
              background: $gray-background;
              color: black !important;
              text-shadow: none;
              border: none;
              text-align: center;
              padding: 5px;
              font-size: $small-font;
            }
          }
        }
        .comparison-timeframe {
          flex: 1;

          .label {
            @include label;
            margin-bottom: $element-margin;
          }

          .selector {
            margin-bottom: $element-margin;
            position: relative;

            .input-group-prepend {
              display: none;
            }

            .form-control {
              border-radius: 10px;
              background: $gray-background;
              color: black !important;
              text-shadow: none;
              border: none;
              text-align: center;
              padding: 5px;
              font-size: $small-font;
            }

            .clear-button {
              position: absolute;
              right: 0px;
              bottom: -15px;
              border-radius: 10px;
              border: none;
              background: transparent;
              color: $font-color;
              letter-spacing: 0.2px;

              font-size: 10px;
              line-height: 12px;
              vertical-align: bottom;

              &:focus,
              &:active {
                outline: none;
              }
            }
          }
        }
      }

      .separator {
        background: $separator-color;
        height: 5px;
        width: 100%;
        border-radius: 10px;
        margin: 20px 0 0px;
      }
    }

    .meter-type-time-bucket-section {
      margin-bottom: $section-margin;
      display: flex;
      gap: 30px;

      .meter-type-selector {
        flex: 1;

        .label {
          @include label;
          margin-bottom: $element-margin;
        }

        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 5px 2px;
          margin-bottom: $element-margin;

          .tag-wrapper {
            min-width: 33%;
            flex: 1;

            display: flex;
            justify-content: space-between;

            .tag {
              width: 97%;

              cursor: pointer;
              background: $gray-background;
              text-align: center;
              border-radius: 10px;
              color: black;
              font-size: $small-font;
              padding: 2px;
              letter-spacing: 0.2px;

              &.active {
                background: $separator-color;
                color: white;
              }
            }
          }
        }
      }

      .time-bucket-selector {
        flex: 1;
      }
    }

    .meter-tree {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      .label {
        @include label;
        margin-bottom: $element-margin;
      }

      .filter {
        margin-bottom: $element-margin;
        display: flex;
        gap: 10px;

        .filter-wrapper {
          flex: 3;

          .form-control {
            background: $gray-background;
            border-radius: 10px;
            height: 35px;
            width: 70%;
          }

          .input-group-prepend {
            display: none;
          }
        }

        .clear-all-wrapper {
          flex: 1;

          .clear-all-button {
            width: 100%;
            border-radius: 10px;
            outline: none;
            border: none;
            height: 35px;
          }
        }
      }

      .tree-selector {
        flex: 1 1 auto;
        position: relative;
        border-radius: 10px;

        background: $gray-background;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;

        .inner-selector {
          border-radius: 10px;
          padding: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -7px;
          left: 0;
          overflow-y: scroll;
          overflow-x: visible;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
            height: 7px;
          }

          &::-webkit-scrollbar-thumb:vertical {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background: $separator-color-vertical;
          }
          &::-webkit-scrollbar-thumb:horizontal {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 5px;
            background: $separator-color;
          }

          &::-webkit-scrollbar-corner {
            background-color: $background-color;
          }
          &::-webkit-scrollbar-track:vertical {
            border-top-right-radius: 5px;
            background: $background-color;
            border-bottom-right-radius: 5px;
          }
          &::-webkit-scrollbar-track:horizontal {
            background: $background-color;
          }
        }
      }
    }

    .building-tree {
      .building-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: $black-font-color;
        margin-bottom: 5px;

        .building-label {
          font-size: 14px;
          margin-left: 5px;
          font-weight: 500;
        }
      }

      .row-wrapper {
        display: flex;
        align-items: center;
        margin: 10px 0;
        background: $gray-background;

        .row-icon {
          color: $black-font-color;
          font-size: 18px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .row-label {
          font-size: 12px;
          color: $black-font-color;
          margin-left: 5px;
          margin-right: 10px;
          white-space: nowrap;
          cursor: pointer;

          display: flex;
          align-items: center;

          .label-vr {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;

            margin-right: 5px;
            background: #17a2b8;
            color: white;
            font-size: 8px;
            line-height: 8px;
            letter-spacing: 0.2px;

            height: 15px;
            width: 15px;
            border-radius: 50%;
          }
        }

        .row-checkbox {
          position: sticky;
          right: 3px;
          border-radius: 10px;
          background: white;
          cursor: pointer;

          .checkbox-outline {
            height: 13px;
            width: 13px;
            border: 1px solid black;
            border-radius: 10px;

            .checkbox-fill {
              height: 100%;
              width: 100%;
              border: 1px solid white;
              border-radius: 10px;
              background: #343a40;
            }
          }
        }
      }
    }

    .collapse-button {
      position: absolute;
      right: -15px;
      background: $background-color;

      display: flex;
      align-items: center;
      justify-content: center;

      height: 30px;
      width: 20px;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      transition: right 0.2s ease-in-out;

      cursor: pointer;
    }
  }

  .charts-area {
    margin-left: $menu-width;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    transition: margin-left 0.2s ease-in-out;
    overflow: scroll;

    &.expanded {
      margin-left: 0;
    }

    .charts-placeholder {
      height: calc(100vh - var.$br-header-height);
      color: #1d2939;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        margin-bottom: 30px;
        opacity: 0.5;
        font-size: 24px;
      }
      h3 {
        margin-bottom: 30px;
        opacity: 0.5;
        font-size: 20px;
      }
    }

    .charts-wrapper {
      display: flex;
      flex-direction: column;
      padding: 15px 30px;
      flex-grow: 1;

      .charts-body {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        flex-grow: 1;
        height: auto;
        gap: 15px;

        .charts-card {
          background: white;
          border-radius: 12px;
          box-shadow: $box-shadow;
          position: relative;
          flex: 1;
          overflow: hidden;

          .highcharts-container {
            position: absolute !important;
            border-radius: 12px;
          }
        }
      }

      .table-wrapper {
        table {
          background: white;
          border: none;
          color: $font-color;
          border-radius: 10px;
          box-shadow: $box-shadow;
          padding: 10px;
        }

        thead {
          border: none;

          th {
            font-size: 14px;
            text-transform: none;
            font-weight: 500;
            cursor: pointer;

            &.meter {
              padding-left: 35px;
            }

            &.timeframe {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &.comparison {
              background: $comparison-highlight;
            }
          }
        }

        tbody {
          tr {
            cursor: pointer;

            &:hover {
              td {
                background: #eeefff !important;

                &.meter {
                  .tag-delete {
                    opacity: 1;
                  }
                }
              }
            }
          }

          tr:last-child {
            td.comparison.start {
              border-bottom-left-radius: 5px;
            }
            td.comparison.end {
              border-bottom-right-radius: 5px;
            }
          }

          td {
            color: $black-font-color;
            font-weight: 400;

            &.meter {
              font-weight: 500;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              text-align: left;
              padding-left: 3px;

              display: flex;
              align-items: center;

              .tag-delete {
                cursor: pointer;
                display: inline-flex;
                vertical-align: middle;
                opacity: 0;
                margin-right: 5px;
              }

              .tag-vr {
                background: #17a2b8;
                color: white;
                font-size: 12px;
                letter-spacing: 0.2px;

                height: 22px;
                width: 22px;

                display: flex;
                align-items: center;
                justify-content: center;

                display: inline-flex;

                border-radius: 10px;
                margin-left: 10px;
              }
            }

            &.comparison {
              background: $comparison-highlight;
            }
          }
        }
      }
    }
  }

  // media breakpoints

  @media (max-width: 991px) {
    .contextual-menu {
      left: 0;
      border-top-left-radius: 0;

      &.closed {
        .collapse-button {
          right: -15px;
        }
      }
    }

    .charts-area {
      margin-left: 0;
    }
  }
  @media (max-width: 1200px) {
    .charts-area {
      margin-left: 0;
    }
  }
  @media (max-width: 415px) {
    .contextual-menu {
      border-top-left-radius: 0;
      width: 300px;

      &.closed {
        left: -300px;
      }
    }
  }
}
