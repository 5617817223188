#IndoorConditions {
  min-height: 90vh;

  .estate-break {
    width: 95%;
    margin-bottom: 30px;
  }

  .br-pagetitle > div {
    padding-left: 13px;
    border-radius: 12px;
  }

  input {
    border: 1px solid #a9a9a9;
    min-width: 270px;

    height: 34px;
    padding: 0 15px;
    border-radius: 12px;
    font-size: 14px;
  }

  .breaches-cards-wrapper {
    border-radius: 10px;
    align-self: flex-start;
    min-width: 270px;
    box-shadow: 0 2px 5px 0px rgba(#000, 0.2);

    h6 {
      font-size: 14px;
      color: #495057;
      margin-bottom: 5px;
      white-space: nowrap;
    }

    .card {
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 13px;

      .view-button {
        position: absolute;
        visibility: hidden;
        transition: visibility 0.2s linear;
        bottom: 0;
        right: 5px;
        font-size: 12px !important;
      }

      &:hover {
        box-shadow: 0 2px 5px 0px rgba(#000, 0.2);
        transition: box-shadow 0.2s ease-in;

        .view-button {
          visibility: visible;
          transition: visibility 0.1s ease-in;
        }
      }

      .card-body {
        padding: 5px;
        display: flex;
        align-items: baseline;
        justify-content: center;

        h1 {
          font-size: 25px;
          color: white;
        }

        span {
          font-size: 16px;
          color: white;
          margin-left: 10px;
        }
      }
    }

    h5 {
      margin: 15px 0px 16px 0px;
      font-size: 16px;
      color: #080808;
    }
  }
  .buildings-cards-wrapper {
    .card-header {
      h5 {
        margin: 0;
        font-size: 16px;
        color: #495057;
      }
    }

    .buildings-wrapper {
      display: flex;

      .col {
        flex: 1 0 250px;
        margin-bottom: 15px;
      }

      .col:empty {
        height: 0;
        margin: 0;
        border: 0;
        background: transparent;
      }
    }

    .building-card {
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 8px 0px rgba(#000, 0.2);
        transition: box-shadow 0.2s ease-in-out;
      }

      .card-body,
      .card-footer,
      .card-header {
        padding-left: 10px;
        padding-right: 10px;
      }

      .card-body {
        height: 130px;
        padding-top: 12px;
        padding-bottom: 10px;
      }

      @media (max-width: 520px) {
        width: 100%;
      }
    }

    .table-wrapper {
      th {
        font-weight: 550;
        padding: 0.2rem;
        font-size: 14px;
        white-space: nowrap;
        font-weight: normal;
        text-transform: none;
      }

      td {
        padding: 0.2rem;
        font-size: 12px;
        white-space: nowrap;
      }
    }
  }

  .buttons-wrapper {
    button {
      flex: 1;
      z-index: 0;
      cursor: pointer;
    }
  }

  .home-modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.85);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      padding: 20px 20px;
      height: 90vh;
      min-width: 70vw;
      overflow-y: scroll;
    }
  }

  .Home {
    text-align: center;
  }

  .Home-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }

  .wellness-detail-icon {
  }

  #QRForEstate {
    .btn {
      border-radius: 10px;
    }
  }
  @keyframes Home-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
