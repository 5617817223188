.logoSpinner {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;

  .spinner {
    width: 60px;
    height: 60px;
    background-image: url("../../assets/spinner/logospinner.png");
    background-size: cover;
    animation: logospin 1.5s linear infinite;
  }

  @keyframes logospin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
