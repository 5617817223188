@use "../../scss/variables.scss" as var;

.SearchSelectDropdown {
  width: 100%;

  &.disabled {
    cursor: not-allowed !important;
  }

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .field-status {
    font-weight: 100;
    color: #707070;
  }

  [class$="control"] {
    @include var.InputStyling;
    box-shadow: none;
    padding: 0;
  }

  [class$="menu"] {
    @include var.InputStyling;

    & > div {
      max-height: 230px;
    }
  }

  [class$="MenuList"] {
    max-height: 230px;
  }
}
