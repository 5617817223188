@use "../../../scss/variables.scss" as var;

$backgroundGray: #f3f3f3;
$cardSpacing: 15px;
$buttonGap: 20px;

@mixin Label {
  font-size: 14px;
  color: #484848;
  font-family: var.$font-family-archivo-semibold;
}

@mixin CardContainer {
  border-radius: 12px;
  background-color: $backgroundGray;
  padding: $cardSpacing;
  margin-bottom: $cardSpacing;
}

.mainWrapper {
  border-radius: 12px;
  margin-bottom: 15px;
  outline: 3px solid transparent;
  transition: outline 1s linear;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;

  &.firstMountOutline {
    animation: firstOutline 1s ease-in-out 1;
  }

  &.movedOutline {
    animation: movedOutline 1s ease-in-out 1;
  }

  @keyframes movedOutline {
    0% {
      outline-color: #286ea7;
    }
    100% {
      outline-color: transparent;
    }
  }
  @keyframes firstOutline {
    0% {
      outline-color: #28a745;
    }
    100% {
      outline-color: transparent;
    }
  }

  .moveWrapper {
    position: absolute;
    left: -30px;
    top: 0;
    gap: 5px;
    display: flex;
    flex-direction: column;

    .moveButton {
      background: white;
      height: 20px;

      &:disabled {
        opacity: 0.5;
      }

      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .antdSwitch {
    outline: none;
    margin-left: 15px;
  }

  .cardHeader {
    min-height: 36px;
    margin: 15px 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 10px;

    .cardTitle {
      font-size: 18px;
      color: #000;
      margin-bottom: 0;
      font-family: var.$font-family-archivo-semibold;
      display: flex;
      align-items: center;

      background: $backgroundGray;
      border-radius: 12px;
      padding: 5px 10px;

      button {
        background: $backgroundGray;
        box-shadow: none;
      }
    }

    .buttonsWrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin-left: auto;
    }

    button {
      font-size: 13px;
    }
  }

  .cardBody {
    padding: 15px 20px 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    flex: 1;

    .config {
      position: relative;
      .leftConfig {
        @media (max-width: 1200px) {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }

        @media (max-width: 1200px) and (min-width: 768px) {
          .timeframeWrapper,
          .bucketWrapper {
            flex: 0 0 50% !important;
            max-width: 50% !important;
          }
        }

        .timeframePickerSection {
          @include CardContainer;
          min-height: 150px;

          @media (max-width: 991px) {
            height: 100%;
          }

          // Targeting the timeframePicker component
          [class*="timeframePicker"] {
            padding: 0 !important;

            [class*="buttonWrapper"] {
              gap: $buttonGap;
            }

            [class*="pickerWrapper"] {
              margin-top: 20px;
            }
          }

          .timeframePickerHeader {
            display: flex;
            margin-bottom: 15px;

            .headerTitle {
              @include Label;
            }
          }

          .buttonWrapper {
            display: flex;
            margin-bottom: 0px;
            flex-wrap: wrap;
            gap: $buttonGap;
            justify-content: center;

            button {
              font-size: 11px;
              border: none !important;
              outline: none !important;
              flex: 1;
              max-width: 60px;
              border-radius: 12px;
              box-shadow: none !important;
              color: #5e656e !important;
            }

            .activeButton {
              background: black !important;
              font-weight: 600 !important;
              box-shadow: none;
              color: #f3f3f3 !important;
              background-color: #5e656e !important;
            }
          }
        }

        .bucketPickerSection {
          @include CardContainer;
          height: 100%;

          .buttonPickerHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            .headerTitle {
              @include Label;
            }
          }

          .buttonWrapper {
            display: flex;
            margin-bottom: 0px;
            flex-wrap: wrap;
            gap: $buttonGap;
            justify-content: space-between;

            button {
              font-size: 11px;
              border: none !important;
              outline: none !important;
              flex: 1;
              max-width: 60px;
              border-radius: 12px;
              box-shadow: none !important;
              color: #5e656e;
            }

            .activeButton {
              background: black !important;
              font-weight: 600 !important;
              box-shadow: none;
              color: #f3f3f3 !important;
              background-color: #5e656e !important;
            }

            .disabledButton {
              color: rgba(#5e656e, 0.25) !important;
            }
          }
        }
      }
      .rightConfig {
        @media (max-width: 1200px) {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
      }
    }

    .chartSection {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
