#AddBuilding {
  .target-container {
    display: flex;
    gap: 20px;
    padding: 0 20px;

    .target-card {
      width: 220px;
      background: lightgray;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      border: 1px solid lightgray;
      background: white;
      position: relative;

      .target-delete {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        opacity: 0;
        cursor: pointer;
      }

      &:hover .target-delete {
        opacity: 1;
      }

      .target-unsaved {
        font-size: 10px;
        position: absolute;
        top: 0;
        left: 0;
        background: #18a2b8;
        color: white;
        border-bottom-right-radius: 10px;
        padding: 5px 10px;
        letter-spacing: 0.5px;
      }

      .target-year {
        font-size: 24px;
        font-weight: 500;
        color: #262626;
        margin-top: 10px;
      }
      .baseline-year {
        margin: 5px 10px;
      }

      .reductions {
        display: flex;
        width: 100%;
        margin: 20px 10px;

        .label {
          font-size: 10px;
          padding: 0;
          margin: 0;
          text-align: center;
        }

        .value {
          padding: 0 10px;
          font-size: 20px;
          font-weight: 500;
          color: #333;
          position: relative;
        }

        .electricity-reductions {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
        }

        .gas-reductions {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
        }
      }
    }
  }

  .create-energy-target-modal {
    .title {
      color: #262626;
    }
    input {
      width: 100%;
    }

    .form-container {
      display: flex;

      .form {
        width: 50%;
      }

      .descriptions {
        width: 50%;
        padding: 30px 50px;

        .description {
          margin-bottom: 15px;

          .title {
            font-weight: 500;
            color: #262626;
          }
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: space-between;

      button {
        min-width: 150px;
      }
    }
  }
}
