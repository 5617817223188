.shareButton {
  button {
    padding: 5px 15px;
    height: 100%;
    font-size: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    background: #415fa9;
    color: white !important;
    border: none;
    outline: none;
    transition: background 0.2s ease-in-out;
    margin-left: auto;

    &:hover {
      color: white !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
