@use "../../scss/variables.scss" as var;

#PageTitle {
  display: flex;
  justify-content: space-between;

  .pagetitle-title {
    font-size: 25px;
    color: #343a40;
  }

  .tag-filter {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    .tag {
      @include var.GreyAndBlackButton;
    }
  }

  @media screen and (max-width: 1400px) {
    display: block;

    .pagetitle-title {
      text-align: center;
    }

    .tag-filter {
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }
}
