.building-card {
  cursor: pointer;

  .card-body,
  .card-footer,
  .card-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .card-body {
    padding-top: 12px;
    padding-bottom: 10px;
  }

  &:hover {
    box-shadow: 0 4px 8px 0px rgba(#000, 0.2);
    transition: box-shadow 0.2s ease-in-out;
  }

  @media (max-width: 520px) {
    width: 100%;
  }
}

.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  &:hover {
    box-shadow: 0 2px 5px 0px rgba(#000, 0.2);
    transition: box-shadow 0.2s ease-in-out;
    text-decoration: underline;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.truncate {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
