#QRZoneView {
  display: block;
  color: #093c52;
  overflow: hidden;

  .top-section {
    height: 15vh;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
      height: 10vh;
    }

    .title {
      letter-spacing: 0.5px;
      font-weight: bolder;
      font-size: 3em;
      padding: 2px 5px 0px;
      margin-bottom: 5px;

      @media screen and (max-width: 1000px) {
        font-size: 2em;
      }
    }

    .subtitle {
      font-size: 1.2em;
      margin-top: 10px;
      margin-bottom: 0px;
      opacity: 0.4;

      @media screen and (max-width: 1000px) {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 0.9em;
        opacity: 0.4;
      }
    }
  }

  .mid-section {
    .card {
      border-radius: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-left: 1%;
      margin-right: 1%;
      width: 420px;

      @media screen and (max-width: 1000px) {
        margin-left: 10px;
        margin-right: 10px;
      }

      .card-header {
        background-color: #093c52;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 7vh;

        .header-refresh {
          display: flex;
          align-items: center;
          margin-bottom: 0px;

          &.spin {
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: 1;
            animation-timing-function: linear;
          }
        }

        .header-title {
          color: white;
          margin: 0;
          font-size: 1.7em;

          @media screen and (max-width: 1000px) {
            margin-right: -30px;
          }
        }
      }

      .card-body {
        height: 55vh;
        font-size: 1.2em;
        padding-top: 5px;
        padding-bottom: 5px;

        @media screen and (max-width: 1000px) {
          height: 58vh;
        }

        .body-main {
          height: 60%;
          color: black;
          display: flex;
          justify-content: center;
          align-items: center;

          .temperature-tab,
          .air-quality-tab {
            color: white;
            height: 90%;
            width: 80%;
            min-width: 280px;
            border-radius: 10px;
            font-size: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .occupancy-tab {
            color: white;
            background-color: green;
            height: 90%;
            width: 80%;
            min-width: 280px;
            border-radius: 10px;
            font-size: 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .occupancy-percentage {
              font-size: 30px;
            }
          }
        }
        .body-bottom {
          height: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .legend-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;

            p {
              margin-bottom: 0px;
              font-size: 16px;
              padding: 5px 7px;
              border-radius: 5px;
            }
          }

          .instructions {
            width: 80%;
            margin: 20px 0px 5px;

            @media (max-height: 640px) {
              width: 100%;
              margin: 10px 0px 0px;
            }
          }

          .color-block {
            height: 20px;
            width: 20px;
            border-radius: 5px;
            display: inline-block;
            vertical-align: text-top;
          }
        }
      }

      .card-footer {
        height: 15vh;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        button {
          border: none;
          background: transparent;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #093c52;
          flex: 1;

          &:disabled {
            opacity: 0.2;
          }
        }
      }
    }
  }

  .bottom-section {
    display: flex;
    height: 23vh;

    .desktop-footer {
      background-color: #093c52;
      height: 10vh;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .clock-wrapper {
        font-size: 2em;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        h5 {
          opacity: 0.3;
          font-weight: normal;
        }
      }

      .powered-by {
        font-size: 1rem;
        margin-right: 20px;
        letter-spacing: 0.5px;
        font-weight: normal;

        a {
          color: white;
          font-size: 2rem;
        }
      }
    }

    &.mobile {
      height: 8vh;

      .timestamp,
      .powered-by {
        margin: 0 10px;
        opacity: 0.4;
        font-size: 11px;
      }
    }
  }
}
