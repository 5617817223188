

.sensor-button-wrapper {
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    position: sticky;
    bottom: 16px;
    background: #fff !important;
}
