input:disabled,
select:disabled {
    color: rgb(84, 84, 84);
    cursor: not-allowed;
    background-color: #ebebe4;
    opacity: 0.7;
}

.table-form .multi-select .dropdown {
    margin-bottom: 2px;
    background-color: #ffffff;
    width: 100%;
}

.table-form .dropdown-heading{
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px dashed #d8d9da !important;
    border-radius: 4px;
    color: rgb(51, 51, 51);
    cursor: default;
    display: table;
    border-spacing: 0px;
    border-collapse: separate;
    height: 26px !important;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.table-form .dropdown-heading-value{
    box-sizing: border-box;
    bottom: 0px;
    color: rgb(51, 51, 51);
    left: 0px;
    line-height: 26px !important;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.savestatus-column {
    display: flex;
    justify-content: space-around;
}