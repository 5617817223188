:root {
  overscroll-behavior: none;
}

.br-header {
  height: $br-header-height;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: $left-nav-blue;
  box-shadow: 0 1px 4px 0px rgba(#000, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include transition($transition-base);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 3px;
    background-color: #fff;
    z-index: 1040;
  }

  @include media-breakpoint-up(lg) {
    left: $br-sideleft-width;
  }
}

.br-header-left {
  display: flex;

  .form-control {
    border: 0;
    border-radius: 0;
    padding: 0 20px;

    &:focus {
      box-shadow: none;
    }
  }

  .input-group {
    border-right: 1px solid $input-border-color;
    align-items: center;
  }

  .input-group-btn {
    .btn {
      background-color: #fff;
      border-radius: 0;
      border: 0;
      color: $gray-600;
      padding-left: 20px;
      padding-right: 20px;
      height: 100%;
      line-height: 1;

      &:focus,
      &:active {
        color: $gray-800;
        box-shadow: none !important;
        background-color: #fff;
      }
    }
  }
}

.br-header-right {
  display: flex;
  align-items: center;

  a {
    color: $gray-600;
    &:hover,
    &:focus {
      color: $gray-800;
    }
  }

  @media (max-width: 480px) {
    display: block;
    position: fixed;
    right: 0;

    .form-control {
      display: none;
    }

    .date-navigator-wrapper {
      min-width: 0px !important;

      .icon-label {
        display: none;
      }
    }
  }

  @media (max-width: 479px) {
    .dropdown {
      &:first-child {
        .dropdown-menu {
          transform: translateX(145px) !important;
        }
      }

      &:nth-child(2) {
        .dropdown-menu {
          transform: translateX(113px) !important;
        }
      }

      &:last-child {
        .dropdown-menu {
          transform: translateX(49px) !important;
        }
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      position: absolute;
      border-top: 0;
      margin-top: 1px;
      box-shadow: 0 1px 4px 0px rgba(#000, 0.16);
      @include border-top-radius(0);

      left: auto !important;
      right: -1px !important;
      top: 55px !important;
      transform: none !important;
      will-change: unset !important;

      &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: 0px;
        right: 0;
        height: 4px;
        background-color: $br-header-bg;
        z-index: 1040;
      }
    }
  }

  .dropdown-footer {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-top: 1px solid $input-border-color;

    a {
      font-size: 12px;
      i {
        margin-right: 5px;
      }
    }
  }
}

.br-header-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 260px;
  padding: 0 15px;

  .date-navigator-wrapper {
    .input-group-prepend {
      display: none;
    }

    .form-control {
      border-radius: 12px !important;
      color: black !important;
      text-shadow: none;
      border: none;
      text-align: center;
      height: 40px;

      .daterangepicker {
        position: relative;
      }
    }
  }
}

.navicon-left {
  width: 60px;
  height: $br-header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition($transition-base);

  a {
    color: $gray-600;
    font-size: 20px;
    @include transition($transition-base);

    &:hover,
    &:focus {
      color: $gray-800;
    }
  }
}

.navicon-left-subscription {
  cursor: pointer;
  width: 60px;
  height: $br-header-height;
  display: flex;
  align-items: center;
  transition: width 0.2s linear;
  overflow: hidden;
  &:hover {
    width: 410px;
  }

  &.trial {
    &:hover {
      width: 340px !important;
    }

    .message {
      min-width: 280px;
    }
  }
  &.trial-expired {
    border-right: 1px solid orange;
    &:hover {
      width: 300px !important;
    }
    .icon-wrapper {
      background: orange !important;
    }

    .message {
      color: white !important;
      background: orange !important;
      min-width: 240px;
    }
  }
  &.expired {
    border-right: 1px solid red;
    &:hover {
      width: 300px !important;
    }
    .icon-wrapper {
      background: red !important;
    }

    .message {
      color: white !important;
      background: red !important;
      min-width: 240px;
    }
  }
  &.overdue {
    border-right: 1px solid orange;
    &:hover {
      width: 430px !important;
    }
    .icon-wrapper {
      background: orange !important;
    }

    .message {
      color: white !important;
      background: orange !important;
      min-width: 370px;
    }
  }

  .icon-wrapper {
    height: $br-header-height;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    height: 100%;
    min-width: 350px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navicon-right {
  display: flex;
  width: $br-header-height;
  height: $br-header-height;
  border-right: 1px solid $input-border-color;
  align-items: center;
  justify-content: center;

  a {
    color: $gray-600;
    font-size: 20px;

    &:hover,
    &:focus {
      color: $gray-800;
    }
  }
}

.br-sideleft {
  position: fixed;
  top: $br-header-height;
  left: -$br-sideleft-width;
  bottom: 0;
  z-index: 100;
  width: $br-sideleft-width;
  background-color: $br-primary;
  padding-left: 10px;
  padding-right: 10px;
  @include transition($transition-base);

  @include media-breakpoint-up(lg) {
    left: 0;
  }
}

.br-logo {
  position: fixed;
  top: 0;
  left: -$br-sideleft-width;
  width: $br-sideleft-width;
  height: $br-header-height;
  background-color: $left-nav-blue;
  font-size: 26px;
  font-weight: 700;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  @include transition($transition-base);

  .dev-banner {
    position: absolute;
    color: red;
    top: 12px;
    right: 13px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 16px;
    background: $left-nav-blue;
    margin: 0;
    font-weight: 600;
  }
  .local-banner {
    position: absolute;
    color: red;
    top: 12px;
    right: 8px;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 15px;
    background: $left-nav-blue;
    margin: 0;
    font-weight: 600;
  }

  @include media-breakpoint-up(lg) {
    left: 0;
  }

  > a {
    color: $gray-900;
    letter-spacing: -0.5px;

    i {
      font-style: normal;
      font-weight: 500;
      color: $cyan;
    }

    span {
      font-weight: 300;
      color: $teal;
      display: inline-block;
      @include transition($transition-base);

      &:first-child {
        padding-right: 2px;
      }
      &:last-child {
        padding-left: 2px;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      span {
        &:first-child {
          transform: translateX(-5px);
        }
        &:last-child {
          transform: translateX(5px);
        }
      }
    }
  }
}

.sidebar-label {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-family: $font-family-roboto;
  color: $off-white;
  letter-spacing: 1px;
  white-space: nowrap;
}

.br-sideleft-menu {
  padding: 0;
  margin: 0;
  @include transition($transition-base);
}

.br-menu-item {
  display: block;
  position: relative;
}

.br-menu-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 11px;
  height: 40px;
  letter-spacing: 0.2px;
  color: $off-white;
  font-size: 14px;
  position: relative;
  @include border-radius(12px);
  @include transition($transition-base);

  &.with-sub {
    &::after {
      content: "\25be";
      font-weight: 400;
      position: absolute;
      font-size: 9px;
      top: calc(50% - 6px);
      right: 10px;
      color: $gray-600;
      @include transition($transition-base);
    }
  }

  &:hover,
  &:focus {
    color: $off-white;
    background-color: lighten($br-primary, 10%);
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.active {
    background-color: #1ea089;
    color: $off-white;
    &::after {
      color: $off-white;
    }
    + .br-menu-sub {
      display: block;
    }

    .new-tag {
      color: #1aa8a8;
    }
  }

  &.show-sub {
    background-color: lighten($br-primary, 10%);
    color: $off-white;
  }

  .new-tag {
    border-radius: 12px;
    background: white;
    color: #0a2f41;
    font-size: 10px;
    padding: 3px 5px;
    margin-left: 5px;
    vertical-align: middle;
  }
}

.menu-item-icon {
  line-height: 0;
  width: 18px;
  @include transition($transition-base);
}

.menu-item-label {
  display: block;
  white-space: nowrap;
  margin-left: 8px;
  margin-right: auto;
}

.menu-item-label {
  @include transition($transition-base);
}

.br-corner-rounder {
  position: fixed;
  background: $light-beige-grey;
  height: 12px;
  width: 12px;
  z-index: 999;
  left: 0;
  top: $br-header-height;
  @include transition($transition-base);

  -webkit-mask-image: -webkit-radial-gradient(
    circle 12px at 100% 100%,
    transparent 0px,
    transparent 12px,
    $br-primary 13px
  );

  mask-image: radial-gradient(
    circle 12px at 100% 100%,
    transparent 0px,
    transparent 12px,
    $br-primary 13px
  );

  @include media-breakpoint-up(lg) {
    background: $br-primary;
    left: $br-sideleft-width;
  }

  @include media-breakpoint-down(md) {
    background: transparent;
  }
}

// show leftpanel
.show-left {
  @include media-breakpoint-down(md) {
    .br-logo,
    .br-sideleft {
      left: 0;
      z-index: 2000;
    }

    .br-corner-rounder {
      background: $br-primary;
      left: $br-sideleft-width;
    }

    .menu-item-label {
      display: block !important;
      opacity: 1 !important;
    }

    .br-contentpanel {
      transform: translateX($br-sideleft-width);
    }
  }
}

.show-left {
  overflow: hidden;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba($br-primary, 0.25);
  }
}

.show-left::before {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.show-left.show-right::before {
  display: block;
}

// mainpanel
.br-mainpanel {
  position: absolute;
  top: $br-header-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;

  @include transition($transition-base);

  @include media-breakpoint-up(lg) {
    left: $br-sideleft-width;
  }

  &.no-sidebar {
    left: 0;
  }
}

#HeaderPublic + .br-mainpanel {
  @media (max-width: 1200px) {
    top: $br-header-height-mobile;
  }
}

.br-pageheader {
  display: flex;
  align-items: center;
  background-color: $light-beige-grey;
  padding: 15px;

  @include media-breakpoint-up(sm) {
    padding: 15px 20px;
  }

  .breadcrumb {
    background-color: transparent;
  }
  .breadcrumb-item {
    &:not(.active) {
      color: $gray-600;
      &:hover,
      &:focus {
        color: $gray-700;
      }
    }

    &.active {
      color: $gray-700;
    }
  }
}

.br-pagetitle {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  .icon {
    font-size: 70px;
    line-height: 0;
    color: $gray-800;
  }

  > div {
    margin-top: 10px;
  }

  h4 {
    margin-bottom: 5px;
    color: $gray-800;
  }

  p {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    padding-top: 15px;

    > div {
      margin-top: 0;
    }
  }
}

.br-pagebody {
  margin-top: 30px;
  padding-top: 100px !important;
  padding: 0 15px;

  @include media-breakpoint-up(sm) {
    padding: 0 30px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.floorplan-background {
  background-image: url("../assets/backgrounds/floorplan.png");
  background-size: 110%;
  background-position: 40% -100px;
  background-repeat: repeat;
  background-color: $light-beige-grey;
}

.br-container {
  width: 90%;
  margin: auto;
  padding: 0 15px 0 15px;

  @media (max-width: 991px) {
    width: 100%;
  }
}

td {
  > input {
    width: 100% !important;
  }
  > select {
    width: 100% !important;
  }
}

.input-group {
  border-radius: 12px !important;

  .input-group-text {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  input {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.button {
  padding: 5px 15px;
  height: 100%;
  font-size: 14px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: #fff !important;
  border: none;
  outline: none !important;

  &:hover {
    color: white !important;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.button.green {
  background: #1c7430;

  &:hover {
    background: #2e9850 !important;
  }
}

.button.orange {
  background: #d77a29;

  &:hover {
    background: #e88f45 !important;
  }
}

.button.red {
  background: #b53737;

  &:hover {
    background: #d04b4b !important;
  }
}

.button.blue {
  background: #1e90ff;

  &:hover {
    background: #4ea4fa !important;
  }
}

.button.light-blue {
  background: #3b7b9f;

  &:hover {
    background: #4f90b3 !important;
  }
}

.Toastify__toast {
  border-radius: 12px !important;
}
