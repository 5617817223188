$classA-color: #4b947e;
$classB-color: #98d8c5;
$classC-color: #efc5a8;
$classD-color: #f8817d;

$gray-background: rgb(210, 210, 210);
$highlight-color: #93f0cb;

$border-color: #cacaca;
$text-color: #343a40;

$card-padding: 20px 50px;

$height: 500px;

#EnergyClassificationReport {
  display: flex;
  flex-direction: column;
  align-items: center;

  .react-hint {
    min-width: 300px;
  }

  .section {
    width: 100%;
    max-width: 1200px;
  }

  .category {
    overflow-x: hidden;

    .column {
      padding: $card-padding;
    }

    .header {
      border-bottom: $border-color 1px solid;

      .title {
        font-size: 22px;
        min-width: 250px;
        color: $text-color;
        letter-spacing: 0.5px;
        font-weight: 500;
      }
    }
  }

  .title-section {
    .title {
      font-size: 26px;
      color: $text-color;
    }

    .sub-title {
      font-size: 14px;
      color: $text-color;
      opacity: 0.5;
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        min-width: 125px;
        padding: 5px 10px;
        margin-left: 10px;
      }
    }

    .form-wrapper {
      width: 100%;
      margin-left: auto;
      margin-bottom: 10px;
    }

    .sharing-form-expanded {
      max-height: 200px;
      overflow: hidden;
      transition: max-height 0.5s ease;
    }
    .sharing-form-collapsed {
      width: 70%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease;
    }

    .email-input {
      flex-grow: 1;
    }

    .btn-send {
      width: 90px;
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  .main-container {
    background-color: white;
    border-radius: 5px;

    .container-header {
      display: flex;
      width: 100%;

      .header-section {
        flex: 1;
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        color: $text-color;
        letter-spacing: 0.5px;
        padding: 20px 20px;
        border: 1px solid $border-color;
        border-bottom: 2px solid $border-color;
        font-weight: 500;
        cursor: pointer;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

        .text {
          transition: transform 0.1s linear;
          text-align: center;
        }

        &:hover .text {
          transform: translate(0, -3px);
        }

        &.disabled {
          color: lightgray;
        }

        &.selected {
          text-decoration: underline;
          border-bottom: 2px solid transparent;
          font-weight: 600;

          &:hover .text {
            transform: none;
          }
        }
      }
    }

    .container-main-window {
      background: white;
      border: 1px solid $border-color;
      border-top: none;
      padding: 35px;

      transition: height 2s linear;
    }
  }

  .no-report-wrapper {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .no-report-icon {
      width: 200px;
      height: 200px;
      filter: grayscale(60%);
    }

    .no-report-message {
      font-size: 20px;
    }
    .no-report-submessage {
      font-size: 15px;
    }
  }

  .overview-wrapper {
    display: flex;
    min-height: $height;
    flex-direction: column;

    .introduction {
      margin-bottom: 10px;
      display: none;

      .title {
        text-decoration: underline;
        color: $text-color;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .paragraph {
        color: $text-color;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .cards {
      display: flex;
      justify-content: space-around;

      .overview-card {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border-radius: 5px;
        border: 2px solid $border-color;
        margin-bottom: 30px;

        .icon {
          position: absolute;
          right: 12px;
          top: 8px;
        }

        .title {
          position: absolute;
          left: 12px;
          top: 8px;
          color: $text-color;
          font-size: 16px;
          letter-spacing: 0.5px;
          font-weight: 500;
        }
        .value {
          font-size: 35px;
          color: $classA-color;
        }
        .subtext {
          font-size: 16px;
          color: $text-color;
        }

        .suffix {
          font-size: 15px;
          position: absolute;
          margin-top: 20px;
          margin-left: 3px;
          color: $border-color;
        }

        &.diagram {
          align-items: flex-start;
          justify-content: flex-end;

          .ribbons {
            margin-bottom: 8px;
            margin-left: 24px;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .summary-table {
      margin: 0px;

      .summary-header {
        border: 2px solid $border-color;
        background: #ededed;
        border-bottom: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .savings-summary-title {
          text-decoration: underline;
          color: $text-color;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
          padding: 10px 0;
        }
        .savings-summary-selectors {
          display: flex;

          .savings-summary-selector {
            color: $text-color;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
            padding: 10px 0;
            margin-left: 50px;
            cursor: pointer;

            &.active {
              text-decoration: underline;
            }
          }
        }
      }
      .summary-wrapper {
        border: 2px solid $border-color;
        border-top: 0;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        display: flex;

        .chart-section {
          display: flex;
          justify-content: center;
          flex-direction: column;

          .chart-table {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            .table-row {
              td {
                border: none;
                color: $text-color;
                font-size: 16px;
              }
              .value,
              .label {
                white-space: nowrap;
              }
              .color {
                .color-square {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        .table-section {
          .table {
            table-layout: fixed;
            border-collapse: collapse;
            margin-top: 10px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            thead {
              td {
                color: $text-color;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-align: center;
                vertical-align: middle;
                border-top: 0;

                &.potential-savings {
                  background-color: $highlight-color;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }
              }
            }

            tbody {
              tr:last-child td:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              td {
                color: $text-color;
                font-size: 16px;
                text-align: center;
                vertical-align: middle;

                &.potential-savings {
                  background-color: $highlight-color;
                  border-color: $highlight-color;
                  border-color: #fff;
                  border-width: 2px;

                  display: flex;
                  justify-content: center;

                  .savings-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .value {
                      font-weight: 500;
                    }
                  }
                }

                &.current-grade {
                  .grade-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }

          .disclaimer {
            font-size: 10px;
            text-align: right;
            color: $text-color;

            .asterisk {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .category-wrapper {
    display: flex;
    min-height: $height;
    flex-direction: column;
    justify-content: space-between;

    .introduction {
      margin-bottom: 10px;

      .title {
        text-decoration: underline;
        color: $text-color;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .paragraph {
        color: $text-color;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .grade-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        height: 100px;
      }
    }

    .pie-and-table {
      .pie-and-table-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 30px 0;
        padding-right: 0;

        .chart-section {
          display: flex;
          justify-content: center;
          flex-direction: column;

          .chart-table {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            .table-row {
              td {
                border: none;
                color: $text-color;
                font-size: 16px;
              }
              .value,
              .label {
                white-space: nowrap;
              }
              .color {
                .color-square {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        .check-table-section {
          border: 2px solid $border-color;
          border-radius: 5px;

          .table {
            table-layout: fixed;
            border-collapse: collapse;
            margin-top: 10px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            thead {
              td {
                color: $text-color;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-align: center;
                vertical-align: middle;
                border-top: 0;

                &.potential-savings {
                  background-color: $highlight-color;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }
              }
            }

            tbody {
              tr:last-child td:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              td {
                color: $text-color;
                font-size: 16px;
                text-align: center;
                vertical-align: middle;

                &.check-name {
                  text-align: left;
                }

                &.current-grade {
                  .grade-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }

                &.info-icon {
                  cursor: help;
                  vertical-align: middle;
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }

    .data-analysis {
      border: 2px solid $border-color;
      border-radius: 5px;
      margin-right: 0;
      margin-left: 0;

      .charts-header {
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          text-decoration: underline;
          color: $text-color;
          margin: 10px 0px;

          text-decoration: underline;
          color: $text-color;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }
        .expand {
          color: $text-color;
          float: right;
          font-size: 35px;
          height: 35px;
          cursor: pointer;
          margin: 10px 0px;
        }
      }
      .charts-body {
        &.expanded {
          max-height: 10000px;
          overflow: hidden;
          transition: max-height 0.5s ease, opacity 0.5s ease 0.2s;
          opacity: 1;
        }

        &.collapsed {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.5s ease, opacity 0.5s ease 0.2s;
          opacity: 0;
        }

        .spinner-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .chart-wrapper {
          padding-left: 5px;
          padding-right: 5px;
          margin: 15px 0;

          .chart-title {
            font-size: 15px;
            color: $text-color;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin-bottom: 5px;
          }
          .chart-description {
            margin-bottom: 20px;
          }

          .chart-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 15px;

            .chart-title {
              color: $gray-background;
            }
          }
        }
      }
    }
  }

  .action-list-wrapper {
    display: flex;
    min-height: $height;

    .action-list-filters {
      flex: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px;

      .filter {
        width: 100%;
        position: relative;

        .filter-input {
          min-width: 200px;
          border-radius: 10px;
          border: 1px solid $text-color;
          padding: 5px 5px 5px 25px;
        }

        .icon-wrapper {
          position: absolute;
          top: 8px;
          left: 5px;
        }
      }

      .filter-by-category,
      .filter-by-grade {
        width: 100%;
        margin-top: 30px;

        .filter-label {
          font-weight: 500;
          font-size: 16px;
          color: $text-color;
        }

        .filter-options {
          margin-top: 15px;
          gap: 10px;
          display: flex;
          flex-direction: column;
          color: $text-color;

          .option {
            display: flex;
            align-items: center;

            .label {
              min-width: 100px;
            }
            .number-wrapper {
              min-width: 50px;

              .number {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background: lightgray;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 500;

                &.colorA {
                  background: $classA-color;
                }
                &.colorB {
                  background: $classB-color;
                }
                &.colorC {
                  background: $classC-color;
                }
                &.colorD {
                  background: $classD-color;
                }
              }
            }
            .checkbox-wrapper {
              min-width: 50px;
              cursor: pointer;

              .checkbox {
                height: 20px;
                width: 20px;
                border: 1px solid black;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                .fill {
                  background: $text-color;
                  height: 14px;
                  width: 14px;
                }
              }
            }
          }
        }
      }
    }
    .action-list {
      flex: 7;
      max-height: $height;
      overflow: scroll;
      gap: 20px;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: $border-color;
      }

      .action {
        width: 95%;

        .action-header {
          border-radius: 20px;
          padding: 10px 5px;
          display: flex;
          align-items: center;
          border: 2px solid;
          cursor: pointer;

          &.expanded {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          &.colorA {
            background: $classA-color;
            border-color: $classA-color;
          }
          &.colorB {
            background: $classB-color;
            border-color: $classB-color;
          }
          &.colorC {
            background: $classC-color;
            border-color: $classC-color;
          }
          &.colorD {
            background: $classD-color;
            border-color: $classD-color;
          }

          .title {
            margin-left: 20px;
            color: $text-color;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
          }
          .expand {
            margin-left: 10px;
            color: $text-color;
            font-size: 30px;
            height: 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
        .action-body {
          border: 4px solid $border-color;
          margin-top: -2px;

          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;

          color: $text-color;

          &.expanded {
            max-height: 10000px;
            overflow: hidden;
            // transition: max-height 0.5s ease, opacity 0.5s ease 0.2s;
            // opacity: 1;
            padding: 20px;
          }

          &.collapsed {
            border: none;

            max-height: 0;
            overflow: hidden;
            height: 0;
          }

          &.colorA {
            border-color: $classA-color;
          }
          &.colorB {
            border-color: $classB-color;
          }
          &.colorC {
            border-color: $classC-color;
          }
          &.colorD {
            border-color: $classD-color;
          }

          .description {
            display: flex;

            align-items: center;

            .grade {
              margin-right: 20px;

              .circle {
                border-radius: 50px;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 18px;

                &.colorA {
                  background-color: $classA-color;
                }
                &.colorB {
                  background-color: $classB-color;
                }
                &.colorC {
                  background-color: $classC-color;
                }
                &.colorD {
                  background-color: $classD-color;
                }
              }
            }
          }

          .requirements {
            margin-top: 30px;

            .possible-upgrades {
              font-weight: 500;
            }

            .requirement {
              display: flex;
              margin-top: 20px;
              align-items: center;

              .grade {
                margin-right: 20px;

                .circle {
                  border-radius: 50px;
                  height: 50px;
                  width: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 500;
                  font-size: 18px;

                  &.colorA {
                    background-color: $classA-color;
                  }
                  &.colorB {
                    background-color: $classB-color;
                  }
                  &.colorC {
                    background-color: $classC-color;
                  }
                  &.colorD {
                    background-color: $classD-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .budget-analysis-wrapper {
    display: flex;
    min-height: $height;
    flex-direction: column;
    justify-content: space-between;

    .project-forecast {
      .project-card {
        border: 2px solid $border-color;
        height: calc(100% - 75px);
        border-radius: 5px;
        margin-top: 5px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .project-card-title {
          margin: 10px 10px 5px;
          color: $text-color;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }
        .project-card-subtitle {
          margin: 5px 10px 5px;
          color: $text-color;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
        }
        .project-card-value {
          display: flex;
          padding-top: 15px;
          align-items: baseline;
          justify-content: center;

          font-size: 50px;
          font-weight: 500;
          letter-spacing: 0.5px;

          color: #3a9772;

          flex: 1;
        }
      }
    }

    .donut-charts {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .donut-chart {
        display: flex;
        flex-direction: column;
        border: 2px solid $border-color;
        margin: 0px 5px;
        border-radius: 5px;
        max-width: 400px;
        min-width: 220px;

        .title {
          text-align: center;
          margin-top: 10px;

          color: $text-color;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        .chart-table {
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

          .table-row {
            td {
              border: none;
              color: $text-color;
              font-size: 13px;
              padding: 0.3em;
            }
            .value,
            .label {
              white-space: nowrap;
            }
            .color {
              .color-square {
                width: 15px;
                height: 15px;
                position: relative;
              }

              .color-square-2 {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 6px;
                left: 6px;
              }
            }
          }
        }
      }
    }
  }

  .classification {
    position: relative;
    display: flex;
    align-items: center;

    height: 24px;
    box-sizing: border-box;
    margin-top: 1px;
    margin-bottom: 1px;

    padding-left: 10px;
    letter-spacing: 5px;

    line-height: 18px;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;

    &.large {
      width: 80px !important;
      transform: scale(2.5);
    }

    &.uniform {
      width: 80px !important;
    }

    &::after {
      content: "";

      position: absolute;
      left: 100%;

      border: 12px solid transparent;
    }

    &.classA {
      width: 60px;

      background-color: $classA-color;
      border-color: $classA-color;

      &::after {
        border-left-color: $classA-color;
      }
    }
    &.classB {
      width: 80px;

      background-color: $classB-color;
      border-color: $classB-color;

      &::after {
        border-left-color: $classB-color;
      }
    }
    &.classC {
      width: 100px;

      background-color: $classC-color;
      border-color: $classC-color;

      &::after {
        border-left-color: $classC-color;
      }
    }
    &.classD {
      width: 120px;

      background-color: $classD-color;
      border-color: $classD-color;

      &::after {
        border-left-color: $classD-color;
      }
    }
    &.disabled {
      background-color: gray !important;
      border-color: gray !important;

      background-color: $border-color !important;
      border-color: $border-color !important;

      &::after {
        border-left-color: gray !important;

        border-left-color: $border-color !important;
      }
    }

    &.small {
      position: relative;
      display: flex;
      align-items: center;

      height: 22px;
      box-sizing: border-box;
      margin-top: 2px;
      margin-bottom: 2px;

      padding-left: 8px;
      letter-spacing: 3px;

      line-height: 15px;
      color: #fff;
      font-family: Arial, sans-serif;
      font-size: 15px;
      font-weight: 700;

      &.large {
        transform: scale(1.5);
      }

      &::after {
        content: "";

        position: absolute;
        left: 100%;

        border: 11px solid transparent;
      }

      &.classA {
        width: 48px;

        background-color: $classA-color;
        border-color: $classA-color;

        &::after {
          border-left-color: $classA-color;
        }
      }
      &.classB {
        width: 64px;

        background-color: $classB-color;
        border-color: $classB-color;

        &::after {
          border-left-color: $classB-color;
        }
      }
      &.classC {
        width: 80px;

        background-color: $classC-color;
        border-color: $classC-color;

        &::after {
          border-left-color: $classC-color;
        }
      }
      &.classD {
        width: 96px;

        background-color: $classD-color;
        border-color: $classD-color;

        &::after {
          border-left-color: $classD-color;
        }
      }
    }
  }

  .indicator {
    display: flex;
    align-items: center;

    height: 30px;
    box-sizing: border-box;
    margin-top: 1px;
    margin-bottom: 1px;

    padding-left: 10px;
    letter-spacing: 5px;

    line-height: 18px;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;

    position: absolute;
    right: 10px;

    &::before {
      content: "";

      position: absolute;
      right: 100%;

      border: 15px solid transparent;
    }

    &.indicator {
      background-color: $text-color;
      border-color: $text-color;
      padding: 0 5px;

      &::before {
        border-right-color: $text-color;
      }
    }
  }

  .optimised-chart {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #3a9772;
  }

  .config-modal-link {
    color: #0866c6;
    cursor: pointer;
  }

  .preview {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    flex: 1;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: gray;
    }

    .line {
      margin: 5px 0;
    }

    .label {
      color: black;
      padding-right: 5px;
    }

    .list-item {
      margin-left: 20px;
    }
  }

  @media (max-width: 600px) {
    .title-section {
      .form-wrapper {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 1200px) {
    .title-section {
      padding-left: 20px;
      padding-right: 20px;

      .form-wrapper {
        margin-bottom: 10px !important;
      }

      .sharing-form-expanded {
        margin: 0 !important;
      }
    }

    .main-container {
      .container-header {
        overflow: scroll !important;

        .header-section {
          padding: 20px 10px !important;
          font-size: 12px !important;
          letter-spacing: 0px !important;
        }
      }

      .container-main-window {
        padding: 20px 15px !important;
        margin-bottom: 0px !important;

        .overview-wrapper {
          .chart-section {
            text-align: center !important;
            align-items: center !important;

            .chart-table {
              max-width: 400px !important;
            }
          }

          .table-section {
            padding: 0px !important;
          }
        }

        .budget-analysis-wrapper {
          .project-card {
            height: 100% !important;
            margin-right: 10px;
          }

          .donut-chart {
            margin-bottom: 10px !important;
          }
        }

        .category-wrapper {
          .summary-wrapper {
            padding: 15px 5px !important;
          }

          .table-section {
            padding: 0px !important;
          }

          .pie-and-table-wrapper {
            padding: 10px 5px !important;
          }

          .info-icon {
            width: 10% !important;
          }
        }
      }
    }
  }
}
