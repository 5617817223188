.BuildingBenchmark {
    &.outOfLogin {
      left: 0 !important;
    }

    .mainWrapper {
        margin: 0 auto;
        box-sizing: border-box;
        width: 100%;
      }
    
      .mainTitle {
        margin-bottom: 0;
      }
    
      .timeframe {
        margin: 5px 0 !important;
      }

      .mainRow {
        margin-top: 25px;
        margin-bottom: 25px;
      }

      .stepsContent {
        padding-top: 0px;
        min-height: 200px;
      }

      .explanation {
        padding: 10px 50px
      }
}