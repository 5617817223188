@use "../../scss/variables.scss" as var;

#MeterDataUpload {
  .file-upload {
    position: relative;
  }

  .file-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .custom-button {
    @include var.PrimaryButton;
    padding: 10px 20px;
    background: #868ba1;
    border-color: #868ba1;
  }

  .custom-file {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .type-selection {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;

    .type-card {
      position: relative;
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 2px solid rgba(0, 0, 0, 0.125);
      height: "auto";
      width: 300px;
      border-radius: 12px;
      padding: 20px;
      cursor: pointer;
      transition: transform 0.1s linear;

      input {
        height: 30px;
      }

      &.selected {
        border: 2px solid #1caba2;
      }

      &.hoverable:hover {
        transform: scale(1.02);
        box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.21);
      }

      &.empty {
        opacity: 0;
        height: 0;
      }

      &.disabled {
        opacity: 1;
        box-shadow: none;
        cursor: default;
      }

      .picture {
        border-radius: 6px;
        height: 150px;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
          border: 1px solid #d6d6d6;
          border: none;
          border-radius: 12px;
          object-fit: cover;
          padding: 10px;

          height: 150px;
          width: 100%;
        }
      }

      .title {
        font-size: 16px;
        font-family: var.$font-family-archivo-semibold;
        color: #343a40;
        min-height: 55px;
        display: flex;
        align-items: center;
      }

      .description {
        font-size: 14px;
      }
    }
  }
}
