$background-color: #2c3d53;
$black-font-color: #343a40;
$separator-color: linear-gradient(to right, #1caf9a 0%, #17a2b8 100%);
$separator-color-vertical: linear-gradient(to bottom, #1caf9a 0%, #17a2b8 100%);
$gray-background: #e9ecef;
$element-margin: 10px;

@mixin label {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.MeterTree {
  .meter-tree {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    border-radius: 12px;

    .title-type-wrapper {
      display: flex;
      margin-bottom: $element-margin;

      .label {
        @include label;
        flex: 2;
      }

      .meter-type-wrapper {
        display: flex;
        gap: 3px;
        flex: 3;

        button {
          flex: 1;
          padding-top: 0;
          padding-bottom: 0;
          color: #868ba1;

          &.btn-secondary {
            background: white;
            border-color: lightgray;
          }
          &.btn-outline-secondary {
            background: white;
            border-color: transparent;
          }
        }
      }
    }

    .filter {
      margin-bottom: $element-margin;
      display: flex;
      gap: 10px;

      .filter-wrapper {
        flex: 2;

        .form-control {
          background: $gray-background;
          border-radius: 5px;
          height: 35px;
          width: 70%;
        }

        .input-group-prepend {
          display: none;
        }
      }

      .clear-all-wrapper {
        flex: 1;

        .clear-all-button {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          outline: none;
          border: none;
        }
      }
    }

    .tree-selector {
      flex: 1 1 auto;
      position: relative;
      border-radius: 5px;

      height: 600px;

      .inner-selector {
        border-radius: 6px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -7px;
        left: 0;
        overflow-y: scroll;
        overflow-x: visible;

        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: gray;
        }
      }
    }
  }

  .building-tree {
    .building-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $black-font-color;
      margin-bottom: 5px;

      .building-label {
        font-size: 14px;
        margin-left: 5px;
        font-weight: 500;
      }
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .row-icon {
        color: $black-font-color;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .row-label {
        font-size: 12px;
        color: $black-font-color;
        margin-left: 5px;
        margin-right: 10px;
        white-space: nowrap;
        cursor: pointer;

        display: flex;
        align-items: center;

        &.reviewed {
          font-weight: 500;
          color: green;
        }

        .label-vr {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;

          margin-right: 5px;
          background: #17a2b8;
          color: white;
          font-size: 8px;
          line-height: 8px;
          letter-spacing: 0.2px;

          height: 15px;
          width: 15px;
          border-radius: 50%;
        }
      }

      .row-checkbox {
        position: sticky;
        right: 3px;
        border-radius: 5px;
        background: white;
        cursor: pointer;

        .checkbox-outline {
          height: 13px;
          width: 13px;
          border: 1px solid black;
          border-radius: 2px;

          .checkbox-fill {
            height: 100%;
            width: 100%;
            border: 1px solid white;
            border-radius: 2px;
            background: #343a40;
          }
        }
      }

      .review-meter {
        margin-left: 30px;
        margin-right: 20px;
        opacity: 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        color: lightgray;
        font-size: 12px;
        white-space: nowrap;
      }

      &:hover {
        .review-meter {
          opacity: 1;
        }
      }
    }
  }

  .collapse-button {
    position: absolute;
    right: -15px;
    bottom: 20px;
    background: $background-color;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 30px;
    width: 20px;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: right 0.2s ease-in-out;

    cursor: pointer;
  }
}
