/* ---------------------------------------------- */
/* ############### 7.1 Background ############### */
/* ---------------------------------------------- */

.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: $gray-100;
}
.bg-gray-200 {
  background-color: $gray-200;
}
.bg-gray-300 {
  background-color: $gray-300;
}
.bg-gray-400 {
  background-color: $gray-400;
}
.bg-gray-500 {
  background-color: $gray-500;
}
.bg-gray-600 {
  background-color: $gray-600;
}
.bg-gray-700 {
  background-color: $gray-700;
}
.bg-gray-800 {
  background-color: $gray-800;
}
.bg-gray-900 {
  background-color: $gray-900;
}

.bg-white-1 {
  background-color: rgba(#fff, 0.1);
}
.bg-white-2 {
  background-color: rgba(#fff, 0.2);
}
.bg-white-3 {
  background-color: rgba(#fff, 0.3);
}
.bg-white-4 {
  background-color: rgba(#fff, 0.4);
}
.bg-white-5 {
  background-color: rgba(#fff, 0.5);
}
.bg-white-6 {
  background-color: rgba(#fff, 0.6);
}
.bg-white-7 {
  background-color: rgba(#fff, 0.7);
}
.bg-white-8 {
  background-color: rgba(#fff, 0.8);
}
.bg-white-9 {
  background-color: rgba(#fff, 0.9);
}

.bg-black-1 {
  background-color: rgba(#000, 0.1);
}
.bg-black-2 {
  background-color: rgba(#000, 0.2);
}
.bg-black-3 {
  background-color: rgba(#000, 0.3);
}
.bg-black-4 {
  background-color: rgba(#000, 0.4);
}
.bg-black-5 {
  background-color: rgba(#000, 0.5);
}
.bg-black-6 {
  background-color: rgba(#000, 0.6);
}
.bg-black-7 {
  background-color: rgba(#000, 0.7);
}
.bg-black-8 {
  background-color: rgba(#000, 0.8);
}
.bg-black-9 {
  background-color: rgba(#000, 0.9);
}

.bg-indigo {
  background-color: $indigo;
}
.bg-purple {
  background-color: $purple;
}
.bg-pink {
  background-color: $pink;
}
.bg-orange {
  background-color: $orange;
}
.bg-teal {
  background-color: $teal;
}

.bg-br-primary {
  background-color: $br-primary;
}
.bg-br-secondary {
  background-color: $br-secondary;
}

// more custom color
.bg-lightsky {
  background-color: #e4f2f2;
}

// background gradient
.bg-mantle {
  @include gradient-x(#514a9d, #24c6dc);
}
.bg-reef {
  @include gradient-x(#3a7bd5, #00d2ff);
}
.bg-transfile {
  @include gradient-x(#cb3066, #16bffd);
}
.bg-neon {
  @include gradient-x(#12fff7, #b3ffab);
}
.bg-delicate {
  @include gradient-x(#e9e4f0, #d3cce3);
}
.bg-emerald {
  @include gradient-x(#56b4d3, #348f50);
}
.bg-mojito {
  @include gradient-x(#1d976c, #93f9b9);
}
.bg-dance {
  @include gradient-x(#f9d423, #ff4e50);
}
.bg-teal-love {
  @include gradient-x(#11ffbd, #aaffa9);
}
.bg-crystal-clear {
  @include gradient-x(#155799, #159957);
}
.bg-grandeur {
  @include gradient-x(#000046, #1cb5e0);
}
.bg-flickr {
  @include gradient-x(#33001b, #e20477);
}
.bg-royal {
  @include gradient-x(#141e30, #243b55);
}
.bg-firewatch {
  @include gradient-x(#cb2d3e, #ef473a);
}

// extra gradient
.bg-teal-info {
  @include gradient-x($teal, theme-color("info"));
}
