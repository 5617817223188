@use "../../scss/variables.scss" as var;

$bgColor: #f3f3f3;
$fontSize: 12px;

.PropertyTable {
  display: flex;
  flex-direction: column;

  .filters-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    .filter-and-addnew {
      display: flex;
      align-items: center;
      gap: 10px;

      .input-filter {
        border: 1px #a9a9a9 solid;
        min-width: 300px;
        height: 25px;
        padding: 0 15px;
        border-radius: 12px;
        font-size: $fontSize;

        &:focus {
          outline: none;
        }
      }

      .add-new-button {
        @include var.PrimaryButton;
        background: green;
        border-color: green;
        height: 34px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 10px 0 7px;

        max-width: 34px;
        overflow: hidden;
        white-space: nowrap;
        transition: max-width 0.2s linear;
        cursor: pointer;

        &:hover {
          max-width: 200px;
        }

        .plus-sign {
          font-size: 26px;
          margin-left: 2px;
          margin-bottom: 3px;
          margin-right: 8px;
        }
      }
    }

    .tag-filter {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      .tag {
        @include var.GreyAndBlackButton;
      }
    }

    .clear-all-button {
      font-size: 13px;
      border: none !important;
      outline: none !important;
      border-radius: 12px;
      box-shadow: none !important;

      font-weight: 400 !important;
      color: white !important;
      background-color: #5e656e;

      &:hover {
        background: #43484f;
      }
    }
  }

  .table-container {
    overflow: scroll;
    background: $bgColor;
    padding: 7px 10px 10px;

    .headers {
      border-bottom: 1px solid #5e656e;

      .header-item {
        .ant-select {
          border-radius: 12px !important;
          border: 1px solid #5e656e !important;
          overflow: hidden;
          cursor: pointer !important;

          .ant-select-selector {
            padding: 0 10px;
          }

          * {
            border: none;
          }
        }
      }
    }

    .property-row {
      border-bottom: 1px solid #dee2e6;
      cursor: pointer;
      display: flex;
      flex-direction: row;

      &.selected-row {
        background: #5e656e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .property-cell {
          color: white;
        }
      }

      &:not(.selected-row):hover {
        background: #d6dce5;
      }

      .property-cell {
        font-size: $fontSize !important;
        padding: 0.25rem 0.5rem;
        color: #5e566e;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .shimmerBG {
      animation-duration: 2.2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
      background: #ddd;
      background: linear-gradient(
        to right,
        #f6f6f6 8%,
        #f0f0f0 18%,
        #f6f6f6 33%
      );
      background-size: 1200px 100%;
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 100% 0;
      }
    }

    @keyframes shimmer {
      0% {
        background-position: -1200px 0;
      }
      100% {
        background-position: 1200px 0;
      }
    }
  }
}
