#ApplianceLogDebug {
  [class*="menu"] {
    z-index: 2;
  }

  .filters {
    gap: 5px 0px;

    [class*="control"] {
      height: 100%;
    }

    [class*="input-group"] {
      height: 38px;
    }
  }
}
