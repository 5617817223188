#AssessmentExecution {
  .preview-box {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px;
    max-height: 525px;
    flex: 1;
    margin-bottom: 1rem;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: gray;
    }

    .line {
      margin: 5px 0;
    }

    .label {
      color: black;
      padding-right: 5px;
    }

    .list-item {
      margin-left: 20px;
    }
  }

  .options-row {
    max-height: 350px;

    .options-wrapper {
      border: 1px solid #ced4da;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      margin-left: 0px;

      .categories,
      .overrides,
      .description {
        height: 100%;
        display: flex;
        flex-direction: column;

        .label {
          margin-bottom: 10px;
        }

        .item-wrapper {
          max-height: 250px;
          overflow-y: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: gray;
          }
        }

        input {
          margin-right: 10px;
        }
      }
    }
  }
}
