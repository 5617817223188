@use "../../../../scss/variables.scss" as var;

$ICON-OPACITY: 0.5;
$menu-width: 220px;
$background-color: #194a5f;
$font-color: var.$off-white;

#EnergyPerformanceTable {
  padding: 0px;

  .pagetitle-title {
    font-family: var.$font-family-archivo !important;
  }

  .list-title {
    margin: 0 0 20px 20px;
  }

  .top-row {
    .cards {
      .summary-cards {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
      }

      .card {
        min-width: 200px;
        height: 100%;
        min-height: 108.5px;
        padding: 12px 15px;
        border-radius: 12px;
        border: none;

        .title {
          color: black;
          display: flex;

          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
        }

        .summary-card-body {
          .data {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 3px;

            .primary {
              font-weight: 500;
              font-size: 25px;
              color: black;
              display: flex;
              justify-content: flex-start;
              margin-top: 5px;
              white-space: nowrap;
            }

            .secondary {
              display: flex;
              opacity: 0.6;
              color: black;
            }
          }
        }
      }
    }

    .selector {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      .title-and-date-selector {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        min-width: 300px;

        .subtitle {
          font-size: 16px;
          color: #cecece;
        }

        .year-selector {
          display: flex;
          flex-direction: row;
          margin: auto;
          margin-top: 10px;

          .selector {
            width: 350px;
          }

          .arrow-left,
          .arrow-right {
            font-size: 50px;
            color: #18a2b8;
            cursor: pointer;
            margin-top: -7px;
          }
        }
      }
    }
  }

  .name-cell {
    .labels {
      .name {
        font-size: 14px;
      }
      .building {
        color: #b5b5b5;
        font-size: 12px;
      }
    }
  }

  .change-cell {
    .cell-container {
      display: flex;
      flex-direction: row;
      margin: auto;
      justify-content: center;

      .value {
        font-size: 14px;
        text-align: center;
        margin-bottom: 3px;
      }
      .secondary-value {
        text-align: center;
        opacity: 0.7;
        font-size: 12px;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }

  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
    border-radius: 5px;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }

  @media screen and (max-width: 1400px) {
    .list-title {
      text-align: center;
    }

    .top-row {
      display: block;

      .summary-cards {
        justify-content: center !important;
        flex-wrap: wrap;
      }

      .selector {
        justify-content: center;
        margin-top: 20px;

        .title-and-date-selector {
          width: 100%;
          margin: auto;
        }
      }

      .year-selector {
        justify-content: center !important;
        margin-right: auto !important;
        margin-left: auto;
      }
    }
  }
}
