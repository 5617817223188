
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px dashed #999;
    background: white;
    position: absolute;
    // border-top-width: 1;
    list-style: none;
    margin-top: 0;
    // max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    // width: calc(300px + 1rem);
    z-index: 2;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    color: black;
    cursor: pointer;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px dashed #999;
  }