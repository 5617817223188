.donutchart-track{
    fill: transparent;
    stroke: #DAE2E5;
    stroke-width: 26;
  }

  .donutchart-indicator {
    fill: transparent;
    stroke: #009688;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
  }
  
  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }
  
  .donutchart-text{
    font-family: 'Roboto';
    fill: #607580;
  }
  .donutchart-text-val{
    font-size:14px;
  }
  .donutchart-text-percent{
    font-size:8px;
  }
  
  