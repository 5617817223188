$buttonGap: 20px;

.buttonWrapper {
    display: flex;
    margin-bottom: 0px;
    flex-wrap: wrap;
    gap: $buttonGap;
    justify-content: space-between;

    button {
      font-size: 14px;
      padding: 10px 15px;
      border: none !important;
      outline: none !important;
      flex: 1;
      border-radius: 12px;
      box-shadow: none !important;
      color: #5e656e;
    }

    .activeButton {
      background: black !important;
    //   font-weight: 500 !important;
      box-shadow: none;
      color: #f3f3f3 !important;
      background-color: #5e656e !important;
    }

    .disabledButton {
      color: rgba(#5e656e, 0.25) !important;
    }
  }