.register-devices-wrapper {
    padding: 0;

    .sensor-filter-wrapper {
        display: flex;
        width: 100%;
        padding-left: 0;

        .sensor-filter {
            padding-left: 0;
            padding-right: 15px;
            flex: 0 1 300px;

            .inline-single-input {
                padding: 8px;
                border-radius: 12px;
            }

            .input-group-prepend, input {
                height: 38px;
            }
        }

        .add-sensor-wrapper {
            flex: 0 1 200px;

            button {
                width: 100%;
            }
        }

        .input-group {
            height: 100%;
            margin-right: 20px;

            .inline-single-input {
                width: 100%;
                border: 1px solid #d8d9da;
            }
        }
    }
}