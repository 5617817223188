@use "../../scss/variables.scss" as var;

#HeaderPublic {
  .home-header-wrapper {
    height: var.$br-header-height;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background-color: var.$left-nav-blue;
    box-shadow: 0 1px 4px 0px rgba(#000, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      justify-content: center;
      height: 40px !important;

      .home-header-right {
        display: none;
      }

      .home-header-logo {
        border: none;
      }
    }
  }

  .home-header-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-header-logo {
    width: var.$br-sideleft-width;
    height: var.$br-header-height;
    font-size: 24px;
    font-weight: 700;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    @media (max-width: 1200px) {
      width: 150px;
    }
  }
}
