.MultiSelectDropdown {
  width: 100%;

  &.disabled {
    cursor: not-allowed !important;

    .dropdown {
      pointer-events: none !important;
    }
  }

  .label {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;
    color: #313131;
  }

  .field-status {
    font-weight: 100;
    color: #707070;
  }

  .multi-select {
    .dropdown {
      //   @include InputStyling;
      border: none;
      height: 40px;

      .dropdown-heading {
        border-radius: 12px !important;
        background-color: #eaeaea !important;
        border: 2px solid #eaeaea !important;
        padding: 2px !important;
        height: 40px !important;

        .dropdown-heading-value {
          border: none;
          padding-left: 11px !important;
          padding-right: 11px !important;
        }
      }
    }

    .dropdown-content {
      border: none !important;
      background: transparent !important;
      margin-top: 10px !important;
      background-color: #eaeaea !important;

      border-radius: 12px !important;
      background-color: #eaeaea !important;
      border: 2px solid #eaeaea !important;

      .select-panel {
        border-radius: 12px !important;
        background-color: #eaeaea !important;
        border: 2px solid #eaeaea !important;
        max-height: 200px !important;

        .select-item {
          background: #eaeaea !important;

          &:hover {
            background: #deebff !important;
          }

          .item-renderer {
            display: flex !important;
            justify-content: flex-start !important;
            align-items: center !important;

            input {
              height: 15px !important;
              width: 30px !important;
            }
          }
        }

        input {
          border: none !important;
        }
      }
    }
  }

  //   color: #313131;
  //   font-weight: lighter;
  //   box-sizing: border-box;
  //   border-radius: 12px;
  //   border: 2px solid #eaeaea;
  //   background-color: #eaeaea;
  //   padding: 7.5px 11px;
  //   width: 100%;

  //   [class$="control"] {
  //     @include InputStyling;
  //     box-shadow: none;
  //     padding: 0;
  //   }

  //   [class$="menu"] {
  //     @include InputStyling;

  //     & > div {
  //       max-height: 230px;
  //     }
  //   }

  //   [class$="MenuList"] {
  //     max-height: 230px;
  //   }
}
