$SPACING: var(--spacing);
$BACKGROUNDCOLOR: var(--backgroundColor);
$BUTTONCOLOR: var(--buttonColor);

.timeframePicker {
  padding: $SPACING;
  border-radius: 12px;
  background: $BACKGROUNDCOLOR;
  position: relative;

  .buttonWrapper {
    display: flex;
    margin-bottom: 0px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: $SPACING;
    gap: 10px;

    button {
      font-size: 11px;
      border: none !important;
      outline: none !important;
      flex: 1;
      max-width: 60px;
      border-radius: 12px;
      box-shadow: none !important;
      color: $BUTTONCOLOR !important;
    }

    .activeButton {
      background: black !important;
      font-weight: 600 !important;
      box-shadow: none;
      color: $BACKGROUNDCOLOR !important;
      background-color: $BUTTONCOLOR !important;
    }
  }

  .pickerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 20px;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 24px;
    }

    .pickerElement {
      width: 230px;

      input {
        text-align: center;
      }

      .picker {
        width: 100% !important;
      }
    }
  }
}

.timeframePickerHeader {
  padding: $SPACING;
  border-radius: 12px;
  position: relative;

  width: 100%;

  .buttonWrapper {
    display: flex;
    margin-bottom: 0px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: $SPACING;
    gap: 10px;

    button {
      font-size: 11px;
      border: none !important;
      outline: none !important;
      flex: 1;
      max-width: 60px;
      color: white;
      border-radius: 12px;
      box-shadow: none !important;
    }

    .activeButton {
      background: white !important;
      color: black;
      font-weight: 600 !important;

      &:hover {
        color: black !important;
      }
    }

    .inactiveButton {
      &:hover {
        background: #eee !important;
        color: black !important;
      }
    }
  }

  .pickerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    background: white;
    padding: 2px;
    border-radius: 12px;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 24px;
    }

    .pickerElement {
      width: 100%;

      input {
        text-align: center;
      }

      .picker {
        width: 100% !important;
      }
    }
  }
}

.popupStyle {
  box-shadow: 4px 4px 19px #343a41;
  border-radius: 12px;
  padding: 0;
}

.popupStyleHidden {
  box-shadow: 4px 4px 19px #343a41;
  border-radius: 12px;
  padding: 0;
  display: none;
}
