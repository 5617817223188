@use "../../scss/variables.scss" as var;

.SignificantEnergyUsersReport {
  text-align: center;
}

.SignificantEnergyUsersReport-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.SignificantEnergyUsersReport-header {
  background-color: var.$primary-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.SignificantEnergyUsersReport-link {
  color: var.$secondary-color;
}

@keyframes SignificantEnergyUsersReport-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
