@use "../../../scss/variables.scss" as var;

#BuildingConditionsDashboard {
  .zone-wrapper {
    display: flex;
    gap: 15px 20px;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .zone-tile:empty {
    min-width: 300px;
  }

  .zone-tile {
    cursor: pointer;
    min-width: 300px;
    max-width: 400px;

    .card {
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
      display: flex;
      flex-direction: row;
      border-radius: 12px;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.21);

      .left {
        width: 40%;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      .right {
        width: 60%;
      }
    }

    &:hover {
      .card {
        transform: translate(0, -5px);
        box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.21);
      }
    }
  }

  .property-label {
    color: rgb(134, 139, 161);
  }

  .wrapper {
    display: flex;
    height: 92vh;

    .main {
      flex-grow: 1;
      padding-top: 5px;
      overflow-y: scroll;
      max-height: 87vh;
    }

    .side {
      width: 400px;
      margin-right: 20px;
    }
  }

  .co2-value-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .label {
      font-size: 10px;
      color: white;
      font-family: Roboto;
      letter-spacing: 0.5px;
    }

    .unit {
      font-size: 32px;
      color: white;
      font-family: Roboto;
      letter-spacing: 0.5px;
    }
  }

  .bottom-bar {
    height: 8vh;
    background-color: #0b2f3f;
    color: white;

    background-color: transparent;

    color: #0b2f3f;

    .clock-wrapper {
      display: flex;
      align-items: center;

      .clock {
        font-size: 2.8vh;
        font-weight: 300;
        padding-left: 35px;
        margin: 0;
        background: rgba(var.$light-beige-grey, 0.7);
      }
    }

    .powered-by-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .powered-by {
        font-size: 2.8vh;
        font-weight: 200;
        padding-right: 25px;
        margin-bottom: 0;
        background: rgba(var.$light-beige-grey, 0.7);

        img {
          width: 250px;
          margin-bottom: 10px;
          margin-left: -8px;
        }
      }
    }
  }

  .side {
    position: relative;
  }

  .corner-switch {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .mobile-bottom-bar {
    background-color: #0b2f3f;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 30px;

    .mobile-powered-by {
      color: white;
      font-size: 2vh;
      font-weight: 200;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .mode-toggle {
        margin: 10px 0;
      }

      a {
        text-align: center;
      }

      img {
        width: 50%;
      }
    }
  }

  @media (max-width: 700px) {
    .wrapper {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .main {
      order: 2;
    }

    .side {
      order: 1;
      margin: 20px;
      margin-right: 30px !important;
    }
  }
}
