.userMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 70px;
  cursor: pointer;
}

.mainInfo {
  .label {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 0.85em;
  }

  .infoWrapper {
    display: flex;
    align-items: center;

    .avatar {
      background: linear-gradient(to right, #17a2b8 0%, #1caf9a 100%);
      margin: 0 15px 0 0;
    }

    .email,
    .org {
      font-size: 0.85em;
      opacity: 0.5;
    }
  }
}

.menuItem {
  color: inherit;
  text-decoration: none;
  font-size: 0.85em;
}
