.manage-meters-container {
    table {
        border-collapse: collapse !important;
        overflow-x: auto;

        tr {
            border: 1px solid #dee2e6 
        }

        td {
            vertical-align: middle;
        }
    }

    .tableWrapper {
        height: 85vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
}

