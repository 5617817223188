@use "../../scss/variables.scss" as var;

.Table {
  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .filter-and-addnew {
      display: flex;
      align-items: center;
      gap: 10px;

      .input-filter {
        border: 1px #a9a9a9 solid;
        min-width: 300px;
        height: 34px;
        padding: 0 15px;
        border-radius: 12px;
        font-size: 14px;

        &:focus {
          outline: none;
        }
      }

      .add-new-button {
        @include var.PrimaryButton;
        background: green;
        border-color: green;
        height: 34px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 10px 0 7px;

        max-width: 34px;
        overflow: hidden;
        white-space: nowrap;
        transition: max-width 0.2s linear;
        cursor: pointer;

        &:hover {
          max-width: 200px;
        }

        .plus-sign {
          font-size: 26px;
          margin-left: 2px;
          margin-bottom: 3px;
          margin-right: 8px;
        }
      }
    }

    .tag-filter {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      .tag {
        @include var.GreyAndBlackButton;
      }
    }
  }

  .table-container {
    overflow: scroll;
    border-radius: 12px;

    table {
      thead {
        tr {
          z-index: 2;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background: white;

          th {
            text-transform: none;
            font-size: 16px;
            border-bottom: 1px solid #dee2e6;

            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }

            &.sticky {
              position: -webkit-sticky;
              position: sticky;
              left: 0;
              background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 8%,
                #ffffff 8%,
                #ffffff 92%,
                rgba(255, 255, 255, 0) 92%
              );
              z-index: 1;
            }
            white-space: nowrap;
          }
        }
      }

      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        }
        .not-available {
          color: #c9c9c9;
        }
      }
    }

    .shimmerBG {
      animation-duration: 2.2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: shimmer;
      animation-timing-function: linear;
      background: #ddd;
      background: linear-gradient(
        to right,
        #f6f6f6 8%,
        #f0f0f0 18%,
        #f6f6f6 33%
      );
      background-size: 1200px 100%;
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: -100% 0;
      }
      100% {
        background-position: 100% 0;
      }
    }

    @keyframes shimmer {
      0% {
        background-position: -1200px 0;
      }
      100% {
        background-position: 1200px 0;
      }
    }
  }
}
