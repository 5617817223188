#MappingAndValidation {
  .building-selection {
    z-index: 3;
    padding-right: 5px;

    .mini-spinner {
      top: 0.3rem;
    }
  }

  .units {
    min-height: 100px;
    margin-top: 25px;

    .controls {
      z-index: 2;
      margin-bottom: 25px;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media (min-width: 1110px) {
        justify-content: space-between;
      }

      .filter-wrapper {
        min-width: 300px;
        margin-bottom: 20px;

        @media (min-width: 1110px) {
          margin-bottom: 0px;
        }

        .filter {
          height: 33px;
          margin-right: 40px;
          display: flex;
          margin-bottom: 20px;

          .icon {
            height: 33px;
            width: 33px;
            background: #17a2b8;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          .filter-input {
            height: 33px;
            border: 3px solid #17a2b8;
            border-radius: 0px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .btn-group {
        min-width: 50%;
        margin-left: 20px;
      }

      .view-report {
        margin-right: 40px;
      }

      .btn-group {
        display: flex;
        justify-content: flex-end;

        button {
          padding: 5px 10px;
          max-width: 130px;
          flex: 1;
          min-height: 44px;
        }
      }
    }

    .unit-section {
      margin-bottom: 30px;

      .section-title {
        color: #343a40;
        text-decoration: underline;
        margin-left: 5px;
      }

      .section-card {
        border-radius: 5px;
        padding: 0;

        .placeholder-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;

          .placeholder {
            margin: 10px;
          }
        }
      }
    }
    .buttons-wrapper {
      background: white;
      border-radius: 5px;
      margin-left: auto;
      margin-right: 1px;
      position: sticky;
      bottom: 15px;
      width: fit-content;

      button {
        flex: 1;
        width: 130px;
        margin: 10px;
      }
    }
  }

  .unit-card {
    padding: 15px;
    margin-bottom: 15px;

    .unit-title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #343a40;
      padding-bottom: 5px;
      align-items: center;
      display: flex;
    }

    .table-wrapper {
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: gray;
      }
    }

    table {
      border: none;
      margin: 0;
    }

    thead {
      tr {
        th {
          padding: 10px 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 15px;
          padding: 5px 15px;

          .calculated-tag {
            background: linear-gradient(to right, #6a11cb 0%, #17a2b8 100%);
            border-radius: 12px;
            color: white;
            padding: 2px 7px;
            margin-left: 5px;
            font-size: 11px;
            vertical-align: text-bottom;
          }

          &.row-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;

            flex-wrap: wrap;
            gap: 10px;

            button {
              padding: 5px 10px;
              font-size: 15px;
              letter-spacing: 0.5px;
              display: flex;
              align-items: center;
              justify-content: center;

              &:disabled {
                background: lightgray;
                border: lightgray;
              }

              &.point-button {
                width: 125px;
              }
            }
          }
        }
      }
    }
  }
}
