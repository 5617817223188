@use "../../scss/variables.scss" as var;

$backgroundGray: #f3f3f3;
$cardSpacing: 15px;
$buttonGap: 20px;

@mixin Label {
  font-size: 14px;
  color: #484848;
  font-family: var.$font-family-archivo-semibold;
}

@mixin CardContainer {
  border-radius: 12px;
  background-color: $backgroundGray;
  padding: $cardSpacing;
}

.leftConfig {
  @media (max-width: 1200px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  @media (max-width: 1200px) and (min-width: 768px) {
    .timeframeWrapper,
    .bucketWrapper,
    .selectedPropertiesWrapper {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  .timeframePickerSection {
    @include CardContainer;
    min-height: 150px;

    @media (max-width: 991px) {
      height: 100%;
    }

    // Targeting the timeframePicker component
    [class*="timeframePicker"] {
      padding: 0 !important;

      [class*="buttonWrapper"] {
        gap: $buttonGap;
      }

      [class*="pickerWrapper"] {
        margin-top: 20px;
      }
    }

    .timeframePickerHeader {
      display: flex;
      margin-bottom: 15px;

      .headerTitle {
        @include Label;
      }
    }

    .buttonWrapper {
      display: flex;
      margin-bottom: 0px;
      flex-wrap: wrap;
      gap: $buttonGap;
      justify-content: center;

      button {
        font-size: 11px;
        border: none !important;
        outline: none !important;
        flex: 1;
        max-width: 60px;
        border-radius: 12px;
        box-shadow: none !important;
        color: #5e656e !important;
      }

      .activeButton {
        background: black !important;
        font-weight: 600 !important;
        box-shadow: none;
        color: #f3f3f3 !important;
        background-color: #5e656e !important;
      }
    }
  }

  .bucketPickerSection {
    @include CardContainer;
    height: 100%;

    .buttonPickerHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .headerTitle {
        @include Label;
      }
    }

    .buttonWrapper {
      display: flex;
      margin-bottom: 0px;
      flex-wrap: wrap;
      gap: $buttonGap;
      justify-content: space-between;

      button {
        font-size: 11px;
        border: none !important;
        outline: none !important;
        flex: 1;
        max-width: 60px;
        border-radius: 12px;
        box-shadow: none !important;
        color: #5e656e;
      }

      .activeButton {
        background: black !important;
        font-weight: 600 !important;
        box-shadow: none;
        color: #f3f3f3 !important;
        background-color: #5e656e !important;
      }

      .disabledButton {
        color: rgba(#5e656e, 0.25) !important;
      }
    }
  }

  .selectedPropertiesSection {
    @include CardContainer;

    .buttonPickerHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .headerTitle {
        @include Label;
      }
    }

    .selectedPropertiesList {
      height: 165px;
      overflow-y: scroll;

      .selectedProperty {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.rightConfig {
  @media (max-width: 1200px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
