@use "../../scss/variables.scss" as var;

.BuildingSelect {
  width: 100%;

  [class$="control"] {
    @include var.InputStyling;
    box-shadow: none;
    padding: 0;
  }

  [class$="menu"] {
    @include var.InputStyling;

    & > div {
      max-height: 230px;
    }
  }

  [class$="MenuList"] {
    max-height: 230px;
  }
}
.BuildingSelectWhite {
  width: 100%;

  [class$="control"] {
    @include var.InputStylingWhite;
    box-shadow: none;
    padding: 0;
  }

  [class$="menu"] {
    @include var.InputStylingWhite;

    & > div {
      max-height: 230px;
    }
  }

  [class$="MenuList"] {
    max-height: 230px;
  }
}
