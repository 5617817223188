.input:disabled {
    color: rgb(84, 84, 84);
    cursor: not-allowed;
    background-color: #e9ecef;
}

.table-form .multi-select .dropdown {
    margin-bottom: 2px;
    background-color: #ffffff;
    width: 100%;
}

.table-form .dropdown-heading{
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px dashed #d8d9da !important;
    border-radius: 4px;
    color: rgb(51, 51, 51);
    cursor: default;
    display: table;
    border-spacing: 0px;
    border-collapse: separate;
    height: 26px !important;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.table-form .dropdown-heading-value{
    box-sizing: border-box;
    bottom: 0px;
    color: rgb(51, 51, 51);
    left: 0px;
    line-height: 26px !important;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
 .geosuggest {
    position: relative;
    width: 100%;
    text-align: left;
  }
  .geosuggest__input {
    margin-bottom: 2px;
    background-color: #ffffff;
    border: 1px dashed #d8d9da;
    width: 100%;
  }

  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 1px dashed #d8d9da;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
            transition: max-height 0.2s, border 0.2s;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }
  
  /**
   * A geosuggest item
   */
  .geosuggest__item {
    padding: .5em .65em;
    cursor: pointer;
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: #f5f5f5;
  }
  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }
  .geosuggest__item__matched-text {
    font-weight: bold;
  }
  