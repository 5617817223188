$SPACING: var(--spacing);

.timeframePickerMini {
  padding: $SPACING;
  border-radius: 12px;
  background: white;
  position: relative;
  width: 100%;

  .pickerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 20px;

    .pickerElement {
      width: 100%;

      input {
        text-align: center;
      }

      .picker {
        width: 100% !important;

        .ant-picker-range-separator {
          padding: 0 !important;
        }
      }
    }
  }
}

.popupStyle {
  box-shadow: 4px 4px 19px #343a41;
  border-radius: 12px;
  padding: 0;
}

.popupStyleHidden {
  box-shadow: 4px 4px 19px #343a41;
  border-radius: 12px;
  padding: 0;
  display: none;
}
