@use "../../../scss/variables.scss" as var;

$backgroundWhite: #fff;
$cardSpacing: 15px;

@mixin Label {
  font-size: 14px;
  color: #000;
  font-family: var.$font-family-archivo-semibold;
}

.pageTitle {
  color: #080808;
  font-size: 20px;
  border-radius: 12px;
  background: white;
  display: inline-block;

  .icon-wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  text-align: center;
  gap: 16px;

  h4 {
    margin: 0;
  }
}

.customLegend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;

  .legendItem {
    display: flex;
    align-items: center;
    gap: 8px;

    .legendColor {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }
}

.openingHoursContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.openingHoursRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.dayName {
  @include Label;
}

.hours {
  @include Label;
  opacity: 0.3;
}

.mainWrapper {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}

.mainTitle {
  margin-bottom: 0;
  margin-top: 20px;
}

.timeframePickerSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.timeframe {
  margin: 20px 0 20px !important;
  text-align: center;
}

.mainRow {
  margin-top: 25px;
  margin-bottom: 25px;
}

.section {
  height: 100%;
}

.noDataMessage {
  opacity: 0.5;
  font-size: 12px;
}

.cardTitleWrapper {
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  @include Label;
  margin-bottom: 10px;
}

.cardOnClick {
  @include Label;
  opacity: 0.3;
  cursor: pointer;
}

.oohpSectionValue {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .valueLabel {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 5px 20px;
  }

  .explanation {
    margin-top: 20px;
    font-size: 10px;
    opacity: 0.7;
    text-align: center;
  }
}

.oohpSectionBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .bar {
    width: 80%;
    margin-top: 40px;
    height: 20px;
    border-radius: 12px;
    display: flex;
    position: relative;

    .thresholdIndicator {
      height: 24px;
      width: 3px;
      background: black;
      position: absolute;
      top: -2px;
      display: flex;
      justify-content: center;

      .bottomLabel {
        position: absolute;
        white-space: nowrap;
        top: 100%;
        font-size: 12px;
      }
    }

    .poorLabel,
    .goodLabel {
      position: absolute;
      top: 28px;
      font-weight: 600;
    }

    .valueIndicator {
      position: absolute;
      height: 23px;
      width: 4px;
      background: transparent;
      display: flex;
      justify-content: center;

      .topLabel {
        position: absolute;
        white-space: nowrap;
        bottom: 50%;
        font-weight: 700;

        display: flex;
        justify-content: center;

        .valueNumber {
          color: black;
          position: absolute;
          font-size: 18px;
          top: -6px;
        }
      }
    }
  }
}

.energyConsumptionPieSection,
.consumptionHoursPieSection {
  min-width: 200px;
}

.tableSection {
  margin-top: -20px;

  .customTable {
    [class*="ant-table-row"] {
      height: 40px;
    }

    [class*="ant-table-cell"] {
      background-color: #fff;
      border: none;
      white-space: nowrap;

      &::before {
        display: none;
      }
    }
  }
}
