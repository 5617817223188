.DialChartContainer {
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex-direction:column;
    padding: 20px;
      
      .base {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        transform: rotate(180deg);
      }

      .message {
        transform: rotate(180deg);
        position: absolute;
        top: 10%;
        font-size: 20px;
        font-weight: 500;
        color: #cccccc;
      }
      
      .radius {
        height: 10px; //thickness of the milestone
        width: 100%;
        display: flex;
        position: absolute;
        justify-content: space-between;
      }
      
      .radius:nth-child(10n+2) {
        -webkit-transform:rotate(18deg);
        -moz-transform:rotate(18deg);
        -o-transform:rotate(18deg);
        -ie-transform:rotate(18deg);
        transform:rotate(18deg); 
      }
      .radius:nth-child(10n+3) {
        -webkit-transform:rotate(36deg);
        -moz-transform:rotate(36deg);
        -o-transform:rotate(36deg);
        -ie-transform:rotate(36deg);
        transform:rotate(36deg);
      }
      .radius:nth-child(10n+4) {
        -webkit-transform:rotate(54deg);
        -moz-transform:rotate(54deg);
        -o-transform:rotate(54deg);
        -ie-transform:rotate(54deg);
        transform:rotate(54deg);
      }
      .radius:nth-child(10n+5) {
        -webkit-transform:rotate(72deg);
        -moz-transform:rotate(72deg);
        -o-transform:rotate(72deg);
        -ie-transform:rotate(72deg);
        transform:rotate(72deg);
      }
      .radius:nth-child(10n+6) {
        -webkit-transform:rotate(90deg);
        -moz-transform:rotate(90deg);
        -o-transform:rotate(90deg);
        -ie-transform:rotate(90deg);
        transform:rotate(90deg);
      }
      .radius:nth-child(10n+7) {
        -webkit-transform:rotate(108deg);
        -moz-transform:rotate(108deg);
        -o-transform:rotate(108deg);
        -ie-transform:rotate(108deg);
        transform:rotate(108deg);
      }
      .radius:nth-child(10n+8) {
        -webkit-transform:rotate(126deg);
        -moz-transform:rotate(126deg);
        -o-transform:rotate(126deg);
        -ie-transform:rotate(126deg);
        transform:rotate(126deg);
      }
      .radius:nth-child(10n+9) {
        -webkit-transform:rotate(144deg);
        -moz-transform:rotate(144deg);
        -o-transform:rotate(144deg);
        -ie-transform:rotate(144deg);
        transform:rotate(144deg);
      }
      .radius:nth-child(10n+10) {
        -webkit-transform:rotate(162deg);
        -moz-transform:rotate(162deg);
        -o-transform:rotate(162deg);
        -ie-transform:rotate(162deg);
        transform:rotate(162deg);
      }
      
      .top,
      .bottom {
        width: 20%;
        border-radius: 5px
      }
  
}