/* ------------------------------------------------- */
/* ############### 3.7 Form Layoyuts ############### */
/* ------------------------------------------------- */

.form-layout-footer {
  .btn {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 12px 20px;
  }
}

/***** FORM LAYOUT 1 *****/
.form-layout-1 {
  border: 1px solid $gray-400;
  padding: 30px;

  .form-group { margin-bottom: 30px; }
  .form-control-label { display: block; }
}

/***** FORM LAYOUT 2 & 3 *****/
.form-layout-2,
.form-layout-3 {
  .form-group {
    position: relative;
    border: 1px solid $gray-400;
    padding: 20px 20px;
    margin-bottom: 0;
    height: 100%;
    @include transition($transition-base);
  }

  .form-group-active {
    background-color: $gray-100;
  }

  .form-control-label {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    display: block;
  }

  .form-control {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: $gray-800;
    @include border-radius(0);
    font-weight: 500;
  }

  .select2-container--default {
    .select2-selection--single {
      background-color: transparent;
      border-color: transparent;
      height: auto;

      .select2-selection__rendered {
        padding: 0;
        font-weight: 500;
      }

      .select2-selection__arrow { height: 26px; }
    }
  }
}

/***** FORM LAYOUT 3 *****/
.form-layout-3 {
  .form-control { font-weight: 400; }

  .select2-container--default {
    .select2-selection--single {
      .select2-selection__rendered {
        font-weight: 400;
      }
    }
  }
}

/***** FORM LAYOUT 4 & 5 *****/
.form-layout-4,
.form-layout-5 {
  padding: 30px;
  border: 1px solid $gray-400;

  .form-control-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

/***** FORM LAYOUT 5 *****/
.form-layout-5 {
  .form-control-label {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }
}

/***** FORM LAYOUT 6 & 7 *****/
.form-layout-6,
.form-layout-7 {
  .row {
    > div {
      border: 1px solid $gray-400;
      padding: 15px 20px;

      &:first-child {
        display: flex;
        align-items: center;
        background-color: $gray-100;
        border-right-width: 0;

        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }

    + .row {
      > div { border-top-width: 0; }
    }
  }

  .form-control {
    border: 0;
    @include border-radius(0);
    padding: 0;
  }
}

/***** FORM LAYOUT 7 *****/
.form-layout-7 {
  .row > div:first-child { justify-content: flex-end; }
}


/* ########### RTL SUPPORT ########## */
.rtl {
  .form-layout-6,
  .form-layout-7 {
    .row {
      > div {
        &:first-child {
          border-right-width: 1px;
          border-left-width: 0;
        }
      }
    }
  }
}
