/* ------------------------------------------------------ */
/* ############### 1. GOOGLE FONTS IMPORT ############### */
/* ------------------------------------------------------ */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text");

/* -------------------------------------------------- */
/* ############### 2. LOCAL FONTS IMPORT ############### */
/* -------------------------------------------------- */

@font-face {
  font-family: "Archivo Bold";
  src: local("Archivo Bold"),
    url("../assets/fonts/Archivo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Regular";
  src: local("Archivo Regular"),
    url("../assets/fonts/Archivo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Light";
  src: local("Archivo Light"),
    url("../assets/fonts/Archivo-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Semibold";
  src: local("Archivo Semibold"),
    url("../assets/fonts/Archivo-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Semibold */
  font-style: normal;
}

@font-face {
  font-family: "Archivo Medium";
  src: local("Archivo Medium"),
    url("../assets/fonts/Archivo-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Ballinger Bold";
  src: local("Ballinger Bold"),
    url("../assets/fonts/Ballinger-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* -------------------------------------------------- */
/* ############### 3. CUSTOM VARIABLES ############## */
/* -------------------------------------------------- */
@import "./variables";

/* ------------------------------------------------------ */
/* ############### 4. BOOTSTRAP FRAMEWORK ############### */
/* ------------------------------------------------------ */
@import "../../node_modules/bootstrap-scss/bootstrap";

/* -------------------------------------------------- */
/* ############### 5. CUSTOM CLASSES ############## */
/* -------------------------------------------------- */
@import "./app";

/* ------------------------------------------------------ */
/* ############### 6. BOOTSTRAP OVERRIDES ############### */
/* ------------------------------------------------------ */
@import "./bootstrap/mixins";
@import "./bootstrap/alerts";
@import "./bootstrap/buttons";
@import "./bootstrap/card";
@import "./bootstrap/carousel";
@import "./bootstrap/custom";
@import "./bootstrap/custom-forms";
@import "./bootstrap/forms";
@import "./bootstrap/form-layouts";
@import "./bootstrap/modal";
@import "./bootstrap/nav";
@import "./bootstrap/pagination";
@import "./bootstrap/popover";
@import "./bootstrap/progress";
@import "./bootstrap/tooltip";
@import "./bootstrap/tables";
@import "./bootstrap/widgets";

/* ------------------------------------------------------------- */
/* ############### 7. UTILITIES / HELPER CLASSES ############### */
/* ------------------------------------------------------------- */

@import "./util/background";
@import "./util/border";
@import "./util/height";
@import "./util/margin";
@import "./util/misc";
@import "./util/padding";
@import "./util/position";
@import "./util/typography";
@import "./util/width";
