.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        justify-content: space-evenly;
      }
}
    
    .countdown-item {
    color: #343a40;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
        line-height: 30px;
    margin: 10px;
        padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;


    @media (max-width: 1440px) {
        width: 100px;
        height: 100px;

      }
      @media (max-width: 768px) {
          height: 50px;
      }
      @media (max-width: 500px) {
        font-size: 25px;
        height: 30px;
        width: 30px;
      }
    }

    .countdown-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
    
          @media (max-width: 768px) {
              display: none;
          }
          @media (max-width: 500px) {
            display: none;
          }
        }
    
    .countdown-item span {
    color: #343a40;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    }


    
    
    footer {
        background-color: #343a40;
        color: #fff;
        font-size: 14px;
        bottom: 0;
        position: fixed;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
    }
    
    footer p {
        margin: 10px 0;
    }
    
    footer i {
        color: red;
    }
    
    footer a {
        color: #3c97bf;
        text-decoration: none;
    }

