#EnergyReportDetail {
  .select-element-disabled .css-1fhf3k1-control {
    background-color: hsl(0, 0%, 100%) !important;
    border-color: hsl(0, 0%, 100%) !important;
  }

  .select-element-disabled .css-107lb6w-singleValue {
    color: #343a40 !important;
  }

  .select-element-disabled .css-1g48xl4-IndicatorsContainer {
    display: none;
  }
}
